import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Divider, Form } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Title from 'commons/components/layout/Title';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import {
  generateCerfaAsync,
  getGeneratedCerfa,
  getGeneratedPDFRequestStatus,
} from 'stores/patients/patientsSlice';
import CERFAPatientStandalone from 'components/cerfa/CERFAPatientStandalone';
import CERFAPrescription from 'components/cerfa/CERFAPrescription';
import CERFASupported from 'components/cerfa/CERFASupported';
import CERFATreatment from 'components/cerfa/CERFATreatment';
import {
  initInsured,
  initSupported,
  initTreatment,
  initPrescription,
  initStandalonePatient,
} from 'stores/patients/patientsTypes';
import { REQUEST_STATUSES } from 'commons/types/common';
import { useCurrentDoctor } from 'stores/doctors/doctorsHooks';
import { useUser } from 'stores/user/userHooks';

export default function CERFAPage() {
  const navigate = useNavigate();
  const [user] = useUser();
  const [doctor] = useCurrentDoctor();

  const dispatch = useAppDispatch();
  const cerfaContent = useAppSelector(getGeneratedCerfa);
  const cerfaGeneratedRequestStatus = useAppSelector(
    getGeneratedPDFRequestStatus,
  );
  const [form] = Form.useForm();

  const [patient] = useState(initStandalonePatient());
  const [insured] = useState(initInsured(patient));
  const [supported] = useState(initSupported());
  const [treatment] = useState(initTreatment());
  const [prescription] = useState(initPrescription());

  const generateAndDownload = useCallback(
    (values: any) => {
      dispatch(
        generateCerfaAsync({
          patient,
          ...values,
          user,
          doctor,
        }),
      );
    },
    [dispatch, patient, user, doctor],
  );

  const downloadCERFA = useCallback(
    (values: any) => {
      generateAndDownload(values);
      form.resetFields();
      window.scrollTo(0, 0);
    },
    [form, generateAndDownload],
  );

  useEffect(() => {
    if (cerfaContent) {
      const downloadLink = document.createElement('a');
      const fileName = 'cerfa.pdf';
      downloadLink.href = cerfaContent || '';
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }, [cerfaContent]);
  const handleBackClick = useCallback(() => navigate(-1), [navigate]);

  return (
    <div className='flex flex-col grow'>
      <Button
        size='small'
        type='text'
        onClick={handleBackClick}
        className='!w-24 !inline-flex !items-center mb-2'
        icon={<ArrowLeftOutlined />}
      >
        <span>Retour</span>
      </Button>
      <Title main>Génération d&nbsp;un CERFA</Title>

      <div className='flex flex-col space-y-4'>
        <Form
          form={form}
          layout='vertical'
          onFinish={downloadCERFA}
          initialValues={{
            patient,
            insured,
            supported,
            treatment,
            prescription,
          }}
          scrollToFirstError
        >
          <CERFAPatientStandalone form={form} />
          <Divider />
          <CERFASupported />
          <Divider />
          <CERFATreatment form={form} />
          <Divider />
          <CERFAPrescription form={form} />
          <Button
            type='primary'
            className='!rounded-[24px] !h-10 !font-semibold mt-8 w-full'
            loading={cerfaGeneratedRequestStatus === REQUEST_STATUSES.PENDING}
            htmlType='submit'
          >
            Générer le CERFA
          </Button>
        </Form>
      </div>
    </div>
  );
}
