import Request from 'commons/libs/requests';
import { Doctor } from 'commons/types/doctorsTypes';
import type { User } from 'stores/user/userTypes';

export async function getDoctor() {
  return (await Request.get(`/api/doctors/`)).data;
}

export interface IPatchDoctor {
  id: string;
  user: User;
  doctor: Doctor;
  ribFile?: File | null;
  signatureFile?: File | null;
}

export async function patchDoctor(params: IPatchDoctor) {
  const formData = new FormData();
  Object.entries(params.user).forEach(([key, value]) => {
    formData.append(key, value);
  });
  Object.entries(params.doctor).forEach(([key, value]) => {
    formData.append(key, value);
  });
  if (params.ribFile) {
    formData.append('rib_file', params.ribFile);
  }
  if (params.signatureFile) {
    formData.append('signature_file', params.signatureFile);
  }

  return (
    await Request.patch(`/api/doctors/${params.id}/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  ).data;
}

export async function getHubspotDoctor(
  email: string,
  firstName: string,
  lastName: string,
) {
  const response = await Request.post('/api/doctors/conversation/', {
    email,
    firstName,
    lastName,
  });
  return response.data.token;
}
