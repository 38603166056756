import { MiddlewareAPI } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { expireSessionAsync } from 'stores/auth/authSlice';

interface AuthActionProps {
  type: string;
  error?: {
    code: string;
  };
}

const authMiddleware =
  (store: MiddlewareAPI<any>) =>
  (next: Dispatch<any>) =>
  (action: AuthActionProps) => {
    if (
      action.type !== 'user/logout/rejected' &&
      action.type !== 'user/expireSession/rejected' &&
      action.error?.code === '403'
    ) {
      store.dispatch(expireSessionAsync());
    }
    return next(action);
  };
export default authMiddleware;
