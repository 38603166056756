import { PDFDocument } from 'pdf-lib';
import Request from 'commons/libs/requests';

export enum CERFA_FIELD_TYPE {
  TEXT,
  RADIO,
  CHECKBOX,
}

export interface CERFAField {
  type: CERFA_FIELD_TYPE;
  pdfKey: string;
}

interface fillCERFAProps {
  response: Response;
  data: Record<string, string | boolean>;
}

const CERFA_FIELDS: Array<CERFAField> = [
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'n et p',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'num immat',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'clé',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'dat nais',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'sec mut',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'n et p ass',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'num immat ass',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'clé ass',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'adress',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'mal',
  },
  {
    type: CERFA_FIELD_TYPE.RADIO,
    pdfKey: 'ALD_choice',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'ppc',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'pres',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'régl',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'typ',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'typ2',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'marq',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'dat exam',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'marmod',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'n et p méd',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'ident',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'dat méd',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'rai soc',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'adres struc',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'num struc',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'mot',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: 'datsm',
  },

  // Prescription
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: '?',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: '?1',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: '?2',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: '?3',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: '?4',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: '?5',
  },
  {
    type: CERFA_FIELD_TYPE.TEXT,
    pdfKey: '?6',
  },
  {
    type: CERFA_FIELD_TYPE.RADIO,
    pdfKey: 'pin_choice',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'ppc1',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'ppc2',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'ppc3',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'oam',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'exam réa',
  },
  {
    type: CERFA_FIELD_TYPE.RADIO,
    pdfKey: 'pinit_choice',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'acc',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'ref',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'ordadmin',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'ordméd',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'convoc',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'Case à cocher43',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'Case à cocher44',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'Case à cocher45',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'Case à cocher46',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'Case à cocher47',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'Case à cocher48',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'Case à cocher49',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'Case à cocher50',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'Case à cocher51',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'Case à cocher52',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'Case à cocher53',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'Case à cocher59',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'Case à cocher62',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'Case à cocher65',
  },
  {
    type: CERFA_FIELD_TYPE.CHECKBOX,
    pdfKey: 'Case à cocher66',
  },
  {
    type: CERFA_FIELD_TYPE.RADIO,
    pdfKey: 'IAH_choice',
  },
  {
    type: CERFA_FIELD_TYPE.RADIO,
    pdfKey: 'sym',
  },
  {
    type: CERFA_FIELD_TYPE.RADIO,
    pdfKey: 'sym1',
  },
  {
    type: CERFA_FIELD_TYPE.RADIO,
    pdfKey: 'sym2',
  },
];

async function drawImage(pdfDoc: PDFDocument) {
  const response = await Request.get('/api/doctors/signature_uri/');
  const imageURL = response.data?.signature_uri || '';
  if (!imageURL) {
    return pdfDoc;
  }

  const pngImageBytes = await fetch(imageURL).then((res) => res.arrayBuffer());
  const image = await pdfDoc.embedPng(pngImageBytes);
  const pngDims = image.scale(0.25);

  [1, 3, 4, 5].forEach((index) => {
    const page = pdfDoc.getPage(index);
    page.drawImage(image, {
      x: 300,
      y: 115,
      width: pngDims.width,
      height: pngDims.height,
    });
  });

  return pdfDoc;
}
export async function fillCERFA({ response, data }: fillCERFAProps) {
  const pdfDoc = await PDFDocument.load(await response.arrayBuffer());
  const form = pdfDoc.getForm();

  CERFA_FIELDS.forEach((field) => {
    const { type, pdfKey } = field;
    const value = data[pdfKey];
    if (
      value === null ||
      value === 'null' ||
      value === undefined ||
      value === 'undefined'
    ) {
      return;
    }

    try {
      switch (type) {
        case CERFA_FIELD_TYPE.TEXT:
          form.getTextField(pdfKey).setText(`${value}`);
          break;
        case CERFA_FIELD_TYPE.CHECKBOX:
          if (value) {
            form.getCheckBox(pdfKey).check();
          }
          break;
        case CERFA_FIELD_TYPE.RADIO:
          if (value) {
            form.getRadioGroup(pdfKey).select(`${value}`);
          }
          break;
        default:
          break;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  });
  form.flatten();

  const pdfWithImage = await drawImage(pdfDoc);
  return pdfWithImage.saveAsBase64({
    dataUri: true,
    updateFieldAppearances: false,
  });
}
