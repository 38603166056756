import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus, REQUEST_STATUSES } from 'commons/types/common';
import { Meeting } from 'commons/types/meetingsTypes';
import type { RootState } from 'stores/store';
import { getMeetings } from './meetingsClient';

export interface MeetingsState {
  list?: Meeting[];
  listRequestStatus?: RequestStatus;
}

const initialState: MeetingsState = {};

export const getMeetingsAsync = createAsyncThunk(
  'meetings/getList',
  (params: URLSearchParams = new URLSearchParams()) => getMeetings(params),
);

export const meetingsSlice = createSlice({
  name: 'meetings',
  initialState,
  reducers: {
    resetMeetingsList: (state) => {
      delete state.list;
      delete state.listRequestStatus;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMeetingsAsync.pending, (state) => {
        state.listRequestStatus = REQUEST_STATUSES.PENDING;
      })
      .addCase(getMeetingsAsync.fulfilled, (state, { payload }) => {
        state.listRequestStatus = REQUEST_STATUSES.SUCCESS;
        state.list = payload;
      })
      .addCase(getMeetingsAsync.rejected, (state) => {
        state.listRequestStatus = REQUEST_STATUSES.ERROR;
      });
  },
});

export const { resetMeetingsList } = meetingsSlice.actions;

export const getMeetingsList = (state: RootState) => state.meetings.list;
export const getMeetingsListRequestStatus = (state: RootState) =>
  state.meetings.listRequestStatus;

export default meetingsSlice.reducer;
