/* eslint-disable import/prefer-default-export */
import { useLocalStoreData } from 'stores/hooks';
import {
  getMeetingsListRequestStatus,
  getMeetingsList,
  getMeetingsAsync,
} from './meetingsSlice';

export function useMeetingList(params?: URLSearchParams) {
  return useLocalStoreData({
    dataGetter: getMeetingsList,
    requestStatusGetter: getMeetingsListRequestStatus,
    thunk: getMeetingsAsync,
    params,
  });
}
