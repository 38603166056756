export const DOCUMENT_TYPES = {
  ORDONNANCE: 'Ordonnance',
  CARTE_SCAN: 'Carte_scan',
  BILAN: 'Bilan',
  ENREGISTREMENT: 'Enregistrement',
  AUTRES: 'Autres',
  JUSTIFICATIF_CMU: 'Justificatif_CMU',
  COMPTE_RENDU: 'Compte_rendu',
  COURRIER_MEDECIN: 'Courrier_medecin',
  DAP_PPC: 'DAP_PPC',
  DAP_OAM: 'DAP_OAM',
  QUITTANCE: 'Quittance',
} as const;

type DocumentTypeKeys = keyof typeof DOCUMENT_TYPES;
export type DocumentType = (typeof DOCUMENT_TYPES)[DocumentTypeKeys];

export interface Document {
  id: string;
  title: string;
  created: string;
  type: DocumentType;
  received: boolean;
  file: string;
}

export type DocumentTypeTextInterface = {
  [key in DocumentType]: string;
};
export const DocumentTypeText: DocumentTypeTextInterface = {
  [DOCUMENT_TYPES.ORDONNANCE]: 'Ordonnance',
  [DOCUMENT_TYPES.CARTE_SCAN]: 'Scan de la carte Vitale',
  [DOCUMENT_TYPES.BILAN]: 'Bilan de sommeil',
  [DOCUMENT_TYPES.ENREGISTREMENT]: 'Enregistrement de sommeil',
  [DOCUMENT_TYPES.AUTRES]: 'Autres',
  [DOCUMENT_TYPES.JUSTIFICATIF_CMU]: 'Justificatif CMU/ALD',
  [DOCUMENT_TYPES.COMPTE_RENDU]: 'Compte rendu du médecin',
  [DOCUMENT_TYPES.COURRIER_MEDECIN]: 'Courrier du médecin',
  [DOCUMENT_TYPES.DAP_PPC]: 'DAP PPC',
  [DOCUMENT_TYPES.DAP_OAM]: 'DAP OAM',
  [DOCUMENT_TYPES.QUITTANCE]: 'Quittance de paiement',
};
