/* eslint-disable import/prefer-default-export */
import { URLSearchParams } from 'url';
import { RcFile } from 'antd/lib/upload/interface';
import dayjs from 'dayjs';
import Request from 'commons/libs/requests';
import { Doctor } from 'commons/types/doctorsTypes';
import { DocumentType } from 'commons/types/documentsTypes';
import {
  Patient,
  PatientStatus,
  PatientNonVenuOption,
} from 'commons/types/patientsTypes';
import { fillCERFA } from 'libs/cerfa';
import {
  Insured,
  Supported,
  Treatment,
  Prescription,
} from 'stores/patients/patientsTypes';
import { User } from 'stores/user/userTypes';

export async function getPatient(patientID: string) {
  const response = await Request.get(`/api/patients/${patientID}/`);
  return response.data;
}

export async function getPatients(
  params: URLSearchParams,
  signal: AbortSignal,
) {
  const response = await Request.get('/api/patients/', { params, signal });
  return response.data;
}

export async function fetchPatientDocuments(patientID: string) {
  const response = await Request.get(`/api/patients/${patientID}/documents/`);
  return response.data;
}

export async function fetchPatientDocument(documentID: string) {
  const response = await Request.get(`/api/documents/${documentID}/`);
  return response.data;
}
export async function createDocument(
  patientID: string,
  document: RcFile,
  type: DocumentType,
) {
  const formData = new FormData();
  formData.append('file', document);
  formData.append('type', type);
  return (
    await Request.post(`/api/patients/${patientID}/upload/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  ).data;
}

interface patchPatientInfo {
  observations?: string;
  status?: PatientStatus;
  nonVenu?: PatientNonVenuOption;
}
export async function patchPatient(
  patientID: string,
  params: patchPatientInfo,
) {
  return (await Request.patch(`/api/patients/${patientID}/`, params)).data;
}

function treatmentPPC(treatment: Treatment) {
  return {
    ppc: true,
    pin_choice: treatment?.ppcInitialPrescription,
    ppc1: treatment?.ppcChoice === 'auto',
    ppc2: treatment?.ppcChoice === 'fixe',
    ppc3: treatment?.ppcChoice === 'double',
    pres: treatment?.ppcPression,
    régl: treatment?.ppcSettings,
    typ: treatment?.ppcInterface?.slice(0, 95),
    typ2: treatment?.ppcInterface?.slice(95),
    marq: treatment?.ppcBrand,
  };
}
function treatmentOAM(treatment: Treatment) {
  return {
    oam: true,
    'dat exam': treatment?.oamDentalExaminationDate
      ? dayjs(treatment?.oamDentalExaminationDate).format('DDMMYYYY')
      : '',
    'exam réa': treatment?.oamDentalExamination,
    pinit_choice: treatment?.oamInitialPrescription,
    marmod: treatment?.oamModel,
  };
}

export async function generateCerfa(
  patient: Patient,
  insured: Insured,
  supported: Supported,
  treatment: Treatment,
  user: User,
  doctor: Doctor,
  prescription: Prescription,
) {
  const PUBLIC_PATH = '/cerfa.pdf';
  const response = await fetch(PUBLIC_PATH);
  const data = {
    // Patient
    'n et p': `${patient?.member.lastName || ''} ${
      patient?.member.firstName || ''
    }`,
    'dat nais': dayjs(patient?.date_of_birth, 'DDMMYYYY', true).isValid()
      ? dayjs(patient?.date_of_birth).format('DDMMYYYY')
      : '',
    // Internal
    'num immat': insured?.immatriculation,
    clé: insured?.key,
    'sec mut': insured?.paymentCenter,

    // External
    'n et p ass': insured?.nameExt,
    'num immat ass': insured?.immatriculationExt,
    'clé ass': insured?.keyExt,
    adress: insured?.nameExt
      ? insured?.addressExt
      : `${patient?.address || ''} ${patient?.city || ''} ${
          patient?.zip || ''
        }`,

    // Prise en charge
    mal: supported?.sick,
    ALD_choice: supported?.ald_choice,

    // Data doctor
    'n et p méd': `${user?.lastName || ''} ${user?.firstName || ''}`,
    ident: `${doctor?.rpps}`,
    'dat méd': dayjs().format('DDMMYYYY'),
    'rai soc': `${doctor?.r_social}`,
    'adres struc': `${doctor?.address}`,
    'num struc': `${doctor?.n_struct}`,

    // Prescription
    'Case à cocher43': prescription?.drowsiness,
    'Case à cocher44': prescription?.snoring,
    'Case à cocher45': prescription?.headaches,
    'Case à cocher46': prescription?.tiredness,
    'Case à cocher47': prescription?.nocturia,
    'Case à cocher48': prescription?.choking,
    'Case à cocher49': prescription?.drowsinessHard,
    'Case à cocher50': prescription?.cardiovascularComorbidities,
    '?': prescription?.cardiovascularComorbiditiesText?.slice(0, 40),
    '?1': prescription?.cardiovascularComorbiditiesText?.slice(40),
    'Case à cocher51': prescription?.respiratoryComorbidities,
    '?2': prescription?.respiratoryComorbiditiesText?.slice(0, 40),
    '?3': prescription?.respiratoryComorbiditiesText?.slice(40),
    'Case à cocher52': prescription?.polygraphy,
    'Case à cocher53': prescription?.polysomnography,
    IAH_choice: prescription?.iah,

    // Case 1
    '?4': prescription?.PPCRenew4MonthText,
    sym: prescription?.PPCRenew4MonthSymptomatology,
    'Case à cocher59': prescription?.PPCRenew4MonthRefused,
    // Case 2
    '?5': prescription?.PPCRenewYearlyText,
    sym1: prescription?.PPCRenewYearlySymptomatology,
    'Case à cocher62': prescription?.PPCRenewYearlyRefused,
    // Case 3
    '?6': prescription?.OAMRenewYearlyText,
    sym2: prescription?.OAMRenewYearlySymptomatology,

    'Case à cocher65': prescription?.PPCRefused,
    'Case à cocher66': prescription?.OAMRefused,
    ...(treatment?.type === 'PPC'
      ? treatmentPPC(treatment)
      : treatmentOAM(treatment)),
  };

  return fillCERFA({ response, data });
}
