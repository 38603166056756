/* eslint-disable import/prefer-default-export */
import { useStoreData } from 'stores/hooks';
import {
  getCurrentPatient,
  getCurrentPatientRequestStatus,
  getPatientAsync,
  getPatientList,
  getPatientListAsync,
  getPatientListRequestStatus,
  IGetPatientListAsync,
  getPatientDocuments,
  getDocumentsRequestStatus,
  getPatientDocumentsAsync,
} from './patientsSlice';

export function usePatientDocument(patientID?: string) {
  return useStoreData(
    getPatientDocuments,
    getDocumentsRequestStatus,
    getPatientDocumentsAsync,
    patientID,
  );
}

export function useCurrentPatient(patientID: string) {
  return useStoreData(
    getCurrentPatient,
    getCurrentPatientRequestStatus,
    getPatientAsync,
    patientID,
  );
}

export function usePatientList(params: IGetPatientListAsync) {
  return useStoreData(
    getPatientList,
    getPatientListRequestStatus,
    getPatientListAsync,
    params,
  );
}
