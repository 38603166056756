import { Button, Popover, Upload as UploadAnt } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';
import { useCallback, useState } from 'react';
import { DocumentType, DocumentTypeText } from 'commons/types/documentsTypes';

export default function Upload({
  patientID,
  types,
  pending = false,
  createDocument,
}: {
  patientID: string;
  types: DocumentType[];
  pending: boolean;
  createDocument: ({
    id,
    document,
    type,
  }: {
    id: string;
    document: RcFile;
    type: DocumentType;
  }) => void;
}) {
  const [openPopOver, setOpenPopOver] = useState(false);
  // To prevent antd from trying to upload the file
  const customRequest = useCallback(() => {}, []);
  const onClick = useCallback(
    (visible: boolean) => setOpenPopOver(visible),
    [],
  );
  const onUpload = useCallback(
    (type: DocumentType) => (file: RcFile) => {
      createDocument({ id: patientID, document: file, type });
      setOpenPopOver(false);
      return ''; // Just a wtf return type from antd ;)
    },
    [createDocument, patientID],
  );

  return (
    <Popover
      placement='top'
      open={openPopOver}
      onOpenChange={onClick}
      trigger={['click']}
      getPopupContainer={(trigger) =>
        (trigger.parentNode as HTMLElement) || document.body
      }
      content={
        <div className='flex flex-col'>
          {types.map((type) => {
            const content = DocumentTypeText[type];
            return (
              <UploadAnt
                key={type}
                name='file'
                multiple={false}
                maxCount={1}
                action={onUpload(type)}
                showUploadList={false}
                customRequest={customRequest}
                className='leading-2 w-56 hover:font-semibold'
              >
                <span className='text-lg'>{content}</span>
              </UploadAnt>
            );
          })}
        </div>
      }
    >
      <Button
        loading={pending}
        className='leading-2'
      >
        Ajouter un document
      </Button>
    </Popover>
  );
}
