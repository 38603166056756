import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AUTH_STATUSES,
  AuthStatus,
  REQUEST_STATUSES,
  RequestStatus,
} from 'commons/types/common';
import type { RootState } from 'stores/store';
import { User } from 'stores/user/userTypes';
import { getMe, postSignOut } from './authClient';

export interface AuthState {
  user?: User;
  authStatus: AuthStatus;
  authRequestStatus?: RequestStatus;
}

const initialState: AuthState = {
  authStatus: AUTH_STATUSES.NOT_CHECKED,
};

interface ISignInProviderAsync {
  token: string;
  clientId: string;
  provider: string;
}

export const checkAuthAsync = createAsyncThunk(
  'auth/check',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (_params: ISignInProviderAsync) => getMe(),
);
export const expireSessionAsync = createAsyncThunk('auth/expireSession', () =>
  postSignOut(),
);
export const signoutAsync = createAsyncThunk('auth/signout', () =>
  postSignOut(),
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkAuthAsync.pending, (state) => {
        state.authRequestStatus = REQUEST_STATUSES.PENDING;
      })
      .addCase(checkAuthAsync.fulfilled, (state, { payload }) => {
        state.authRequestStatus = REQUEST_STATUSES.SUCCESS;
        state.authStatus = AUTH_STATUSES.LOGGED;
        state.user = payload;
      })
      .addCase(checkAuthAsync.rejected, (state) => {
        state.authRequestStatus = REQUEST_STATUSES.ERROR;
        state.authStatus = AUTH_STATUSES.NOT_LOGGED;
      })
      .addCase(signoutAsync.fulfilled, (state) => {
        state.authStatus = AUTH_STATUSES.LOGGED_OUT;
        delete state.user;
      });
  },
});

export const getUser = (state: RootState) => state.auth.user;
export const getAuthStatus = (state: RootState) => state.auth.authStatus;
export const getAuthRequestStatus = (state: RootState) =>
  state.auth.authRequestStatus;

export default authSlice.reducer;
