import { Row, Radio, Form, Checkbox } from 'antd';
import { Fragment } from 'react';

export default function CERFASupported() {
  return (
    <Fragment>
      <Row gutter={8}>
        <Form.Item
          valuePropName='checked'
          name={['supported', 'sick']}
          noStyle
        >
          <Checkbox>Maladie</Checkbox>
        </Form.Item>
      </Row>
      <Row gutter={8}>
        <span className='ant-form-text'>Soins en rapport avec une ALD</span>
      </Row>
      <Row gutter={8}>
        <Form.Item name={['supported', 'ald_choice']}>
          <Radio.Group name='ald_choice'>
            <Radio value='yes'>Oui</Radio>
            <Radio value='no'>Non</Radio>
          </Radio.Group>
        </Form.Item>
      </Row>
    </Fragment>
  );
}
