import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useCallback } from 'react';

interface PaginationProps {
  currentPage?: number;
  isOnLastPage?: boolean;
  onChangePage?: (page: number) => void;
  loading?: boolean;
}

export default function Pagination({
  loading = false,
  currentPage = 1,
  isOnLastPage = false,
  onChangePage = () => {},
}: PaginationProps) {
  const onClickPage = useCallback(
    (pageNumber: number) => () => onChangePage(pageNumber),
    [onChangePage],
  );

  return (
    <div className='flex items-center justify-between space-x-1'>
      <nav
        className='flex items-center space-x-1'
        aria-label='Pagination'
      >
        <Button
          disabled={loading || currentPage === 1}
          aria-label='Previous'
          onClick={onClickPage(currentPage - 1)}
          color='secondary'
          icon={<LeftOutlined />}
          className='!inline-flex !items-center justify-center'
        />
        <Button
          disabled={loading || isOnLastPage}
          aria-label='Next'
          onClick={onClickPage(currentPage + 1)}
          color='secondary'
          icon={<RightOutlined />}
          className='!inline-flex !items-center justify-center'
        />
      </nav>
    </div>
  );
}
