import { LinkOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input } from 'antd';
import { useCallback } from 'react';
import { Doctor } from 'commons/types/doctorsTypes';
import {
  REQUIRED_FIELD_RULES,
  REQUIRED_PHONE_NUMBER_RULES,
  REQUIRED_RPPS_NUMBER_RULES,
  REQUIRED_STRUCT_NUMBER_RULES,
} from 'libs/validation';
import { User } from 'stores/user/userTypes';

interface UserFormProps {
  onSave?: (newUser: User) => void;
  user?: User;
  doctor?: Doctor;
  disabled?: boolean;
}

export default function UserForm({
  user = undefined,
  doctor = undefined,
  onSave = () => {},
  disabled = false,
}: UserFormProps) {
  const onSubmit = useCallback(
    (values: User) => {
      onSave(values);
    },
    [onSave],
  );

  return (
    <Form
      initialValues={{ user, doctor }}
      onFinish={onSubmit}
      layout='vertical'
    >
      <Divider
        orientation='left'
        orientationMargin='0'
        className='!text-sm uppercase'
      >
        Contact
      </Divider>
      <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
        <Form.Item
          label='Prénom'
          name={['user', 'firstName']}
          rules={REQUIRED_FIELD_RULES}
        >
          <Input disabled={disabled} />
        </Form.Item>
        <Form.Item
          label='Nom'
          name={['user', 'lastName']}
          rules={REQUIRED_FIELD_RULES}
        >
          <Input disabled={disabled} />
        </Form.Item>
      </div>
      <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
        <Form.Item
          label='Adresse email'
          name={['user', 'email']}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label='Téléphone'
          name={['user', 'phone']}
          rules={REQUIRED_PHONE_NUMBER_RULES}
        >
          <Input disabled={disabled} />
        </Form.Item>
      </div>
      <Divider
        orientation='left'
        orientationMargin='0'
        className='!text-sm uppercase'
      >
        FACTURATION
      </Divider>
      <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
        <Form.Item
          label='Adresse'
          name={['doctor', 'address']}
          rules={REQUIRED_FIELD_RULES}
        >
          <Input disabled={disabled} />
        </Form.Item>
        <Form.Item
          label='Code postal'
          name={['doctor', 'zip']}
          rules={REQUIRED_FIELD_RULES}
        >
          <Input disabled={disabled} />
        </Form.Item>
      </div>
      <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
        <Form.Item
          label='Ville'
          name={['doctor', 'city']}
          rules={REQUIRED_FIELD_RULES}
        >
          <Input disabled={disabled} />
        </Form.Item>
        {doctor?.rib && (
          <Form.Item
            label='RIB'
            name={['doctor', 'rib']}
            rules={REQUIRED_FIELD_RULES}
          >
            <a
              href={doctor?.rib}
              target='_blank'
              rel='noreferrer noopener'
            >
              <LinkOutlined className='mr-2' />
              Voir le RIB
            </a>
          </Form.Item>
        )}
      </div>
      <Divider
        orientation='left'
        orientationMargin='0'
        className='!text-sm uppercase'
      >
        ADMINISTRATIF
      </Divider>
      <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
        <Form.Item
          label='Spécialité'
          name={['doctor', 'speciality']}
          rules={REQUIRED_FIELD_RULES}
        >
          <Input disabled={disabled} />
        </Form.Item>
        <Form.Item
          label='Nº RPPS'
          name={['doctor', 'rpps']}
          rules={REQUIRED_RPPS_NUMBER_RULES}
        >
          <Input disabled={disabled} />
        </Form.Item>
      </div>
      <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
        <Form.Item
          label='Raison sociale'
          name={['doctor', 'r_social']}
          rules={REQUIRED_FIELD_RULES}
        >
          <Input disabled={disabled} />
        </Form.Item>
        <Form.Item
          label='Nº de structure (AM, FINESS ou SIRET)'
          name={['doctor', 'n_struct']}
          rules={REQUIRED_STRUCT_NUMBER_RULES}
        >
          <Input disabled={disabled} />
        </Form.Item>
      </div>
      {!disabled && (
        <div className='flex justify-end'>
          <Form.Item>
            <Button
              htmlType='submit'
              type='primary'
            >
              Valider
            </Button>
          </Form.Item>
        </div>
      )}
    </Form>
  );
}
