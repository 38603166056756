export interface PeriodService {
  id?: number;
  serviceId?: number;
}

export interface Period {
  id?: string;
  startTime: string;
  endTime: string;
  periodServiceList?: PeriodService[];
  locationId?: string;
}

export interface DayOff {
  id?: string;
  startDate: string;
  endDate: string;
  name: string;
  repeat: boolean;
}

export interface SpecialDay {
  id?: string;
  startDate: string;
  endDate: string;
  periodList: Period[];
}

export interface WeekDay {
  id?: string;
  dayIndex: number;
  startTime: string;
  endTime: string;
  timeOutList: string[];
  periodList: Period[];
}

export interface Timeslot {
  email: string;
  firstName: string;
  lastName: string;
  note: string;
  phone: string;
  wp_id: string;
  dayOffList: DayOff[];
  specialDayList: SpecialDay[];
  weekDayList: WeekDay[];
  type: string;
}

export const initDefaultTimeslot: () => Timeslot = () => ({
  email: '',
  firstName: '',
  lastName: '',
  note: '',
  phone: '',
  wp_id: '',
  weekDayList: [],
  dayOffList: [],
  specialDayList: [],
  type: '',
});
