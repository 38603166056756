import { Row, Col, Tabs, Form, Input, FormInstance } from 'antd';
import type { TabsProps } from 'antd';
import { Fragment, FormEvent, useCallback, useState } from 'react';

interface CERFAPatientDetailProps {
  required: boolean;
}

function Internal({ required }: CERFAPatientDetailProps) {
  const preventEnterDefaultBehavior = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => event.preventDefault(),
    [],
  );

  return (
    <Fragment>
      <Row gutter={8}>
        <Col>
          <Form.Item
            name={['insured', 'immatriculation']}
            label='Immatriculation Patient'
            rules={[{ required, len: 13 }]}
            style={{ width: 320 }}
          >
            <Input
              name='immatriculation'
              maxLength={13}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={['insured', 'key']}
            label='Clef'
            rules={[{ required, len: 2 }]}
          >
            <Input
              name='key'
              maxLength={2}
              style={{ width: 45 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Form.Item
          name={['insured', 'paymentCenter']}
          label={`
            Nom et n° du centre de paiement ou de la section
            mutualiste (pour les salariés) ou nom et n° de
            l'organisme conventionné (pour les non salariés)
          `}
          style={{ width: 512 }}
        >
          <Input.TextArea
            name='paymentCenter'
            rows={2}
            cols={63}
            onPressEnter={preventEnterDefaultBehavior}
            maxLength={50}
          />
        </Form.Item>
      </Row>
    </Fragment>
  );
}

function External({ required }: CERFAPatientDetailProps) {
  const preventEnterDefaultBehavior = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => event.preventDefault(),
    [],
  );

  return (
    <Fragment>
      <Row gutter={8}>
        <Form.Item
          name={['insured', 'nameExt']}
          label={"Nom de l'assuré"}
          rules={[{ required }]}
          style={{ width: 512 }}
        >
          <Input
            name='nameExt'
            maxLength={86}
          />
        </Form.Item>
      </Row>
      <Row gutter={8}>
        <Col>
          <Form.Item
            name={['insured', 'immatriculationExt']}
            label={"Immatriculation de l'assuré"}
            rules={[{ required, len: 13 }]}
            style={{ width: 320 }}
          >
            <Input
              name='immatriculationExt'
              maxLength={13}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={['insured', 'keyExt']}
            label='Clef'
            rules={[{ required, len: 2 }]}
          >
            <Input
              name='keyExt'
              maxLength={2}
              style={{ width: 45 }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Form.Item
          name={['insured', 'addressExt']}
          label={"Adresse de l'assuré"}
          rules={[{ required }]}
        >
          <Input.TextArea
            name='addressExt'
            rows={3}
            cols={63}
            onPressEnter={preventEnterDefaultBehavior}
            maxLength={155}
          />
        </Form.Item>
      </Row>
    </Fragment>
  );
}

const PATIENT_INFOS = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};
interface CERFAPatientProps {
  form: FormInstance;
}
export default function CERFAPatient({ form }: CERFAPatientProps) {
  const [tab, setTab] = useState(PATIENT_INFOS.INTERNAL);
  const items: TabsProps['items'] = [
    {
      key: PATIENT_INFOS.INTERNAL,
      label: "Le patient est l'assuré",
      children: <Internal required={tab === PATIENT_INFOS.INTERNAL} />,
    },
    {
      key: PATIENT_INFOS.EXTERNAL,
      label: "Le patient n'est pas l'assuré",
      children: (
        <Fragment>
          <Internal required={tab === PATIENT_INFOS.EXTERNAL} />
          <External required={tab === PATIENT_INFOS.EXTERNAL} />
        </Fragment>
      ),
    },
  ];
  const onChangeTab = useCallback(
    (value: string) => {
      setTab(value);
      form.resetFields(['insured']);
    },
    [form],
  );

  return (
    <Tabs
      items={items}
      onChange={onChangeTab}
    />
  );
}
