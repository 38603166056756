import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FilePdfOutlined,
  MailOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';
import { Button, Input, Select, Spin } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';
import dayjs from 'dayjs';
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Title from 'commons/components/layout/Title';
import Upload from 'commons/components/upload/Upload';
import { REQUEST_STATUSES } from 'commons/types/common';
import {
  DOCUMENT_TYPES,
  DocumentTypeText,
  DocumentType,
} from 'commons/types/documentsTypes';
import {
  MEETING_STATUSES,
  Meeting,
  MEETING_TYPES,
} from 'commons/types/meetingsTypes';
import {
  PatientStatus,
  PatientNonVenuOption,
  PATIENT_STATUSES,
  PATIENT_NON_VENU_OPTIONS,
} from 'commons/types/patientsTypes';
import CERFAModal from 'components/cerfa/CERFAModal';
import InformationItem from 'components/informations/InformationItem';
import Card from 'components/layout/Card';
import useDebounce from 'hooks/useDebounce';
import { useCurrentDoctor } from 'stores/doctors/doctorsHooks';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import {
  useCurrentPatient,
  usePatientDocument,
} from 'stores/patients/patientsHooks';
import {
  createDocumentAsync,
  getCreateDocumentRequestStatus,
  getPatientDocumentAsync,
  getPatientDocumentsAsync,
  getUpdatePatientRequestStatus,
  resetCurrentPatient,
  updatePatientAsync,
} from 'stores/patients/patientsSlice';
import { useUser } from 'stores/user/userHooks';

export default function Patient() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { patientID } = useParams();
  const [open, setOpen] = useState(false);
  const [user] = useUser();
  const [doctor] = useCurrentDoctor();
  const [documents] = usePatientDocument(patientID || '');
  const createDocumentRequestStatus = useAppSelector(
    getCreateDocumentRequestStatus,
  );
  const patchPatientRequestStatus = useAppSelector(
    getUpdatePatientRequestStatus,
  );
  const [currentPatient, currentPatientRequestStatus] = useCurrentPatient(
    patientID || '',
  );
  const nextMeeting = useMemo(() => {
    const sortedMeetings = (currentPatient?.meetings || [])
      .slice()
      .filter((meeting) => meeting.status === MEETING_STATUSES.ACCEPTED)
      .sort((a: Meeting, b: Meeting) => dayjs(b.start_date).diff(a.start_date));
    return sortedMeetings.length > 0 ? sortedMeetings[0] : undefined;
  }, [currentPatient]);

  const informations: { name: ReactNode; value: ReactNode }[] = useMemo(() => {
    if (!currentPatient) {
      return [];
    }
    const dateOfBirth = dayjs(currentPatient.date_of_birth, 'DD/MM/YYYY');
    const age = Math.floor(dayjs().diff(dateOfBirth, 'years'));
    const nextMeetingComment =
      nextMeeting?.type === MEETING_TYPES.RDV_3
        ? currentPatient.commentaires_rdv_3
        : currentPatient.rdv_suivi___commentaires || '';

    const informationsArray = [
      {
        name: 'Nom',
        value: `${currentPatient.member.firstName} ${currentPatient.member.lastName}`,
      },
      {
        name: 'Âge - Date de naissance',
        value: dateOfBirth.isValid()
          ? `${age} an${age > 1 ? 's' : ''} - ${dateOfBirth.format(
              'DD/MM/YYYY',
            )}`
          : null,
      },
      {
        name: 'Adresse email',
        value: currentPatient.email,
      },
      {
        name: 'Téléphone',
        value: currentPatient.mobilephone,
      },
      {
        name: 'Code postal',
        value: currentPatient.zip,
      },
      {
        name: 'NIC',
        value: currentPatient.nic,
      },
      {
        name: 'Dossier',
        value: currentPatient.dossier ? (
          <a
            className='underline text-primary'
            href={currentPatient.dossier}
            target='_blank'
            rel='noreferrer noopener'
          >
            Lien dossier
          </a>
        ) : (
          'Pas de dossier…'
        ),
      },
    ];

    if (nextMeeting) {
      informationsArray.push(
        {
          name: 'Prochain rendez-vous',
          value: `${dayjs(nextMeeting.start_date).format('D MMMM à HH:mm')}`,
        },
        {
          name: 'Paiement',
          value: nextMeetingComment,
        },
        {
          name: '',
          value: null,
        },
      );
    }

    return informationsArray;
  }, [currentPatient, nextMeeting]);
  const setOpenModal = useCallback(
    (isOpen: boolean) => () => setOpen(isOpen),
    [],
  );
  const handleBackClick = useCallback(() => navigate(-1), [navigate]);
  const onChangeObservations = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (currentPatient) {
        dispatch(
          updatePatientAsync({
            patientID: currentPatient?.id,
            observations: event.target.value,
          }),
        );
      }
    },
    [currentPatient, dispatch],
  );
  const onChangePatientStatus = useCallback(
    (status: PatientStatus) => {
      if (currentPatient) {
        dispatch(
          updatePatientAsync({
            patientID: currentPatient?.id,
            status,
          }),
        );
      }
    },
    [currentPatient, dispatch],
  );
  const onChangePatientNonVenu = useCallback(
    (nonVenu: PatientNonVenuOption) => {
      if (currentPatient) {
        dispatch(
          updatePatientAsync({
            patientID: currentPatient?.id,
            nonVenu,
          }),
        );
      }
    },
    [currentPatient, dispatch],
  );
  const debouncedOnChangeObservations = useDebounce(onChangeObservations, 500);
  const statusOptions = useMemo(
    () =>
      Object.values(PATIENT_STATUSES).map((value) => ({ value, label: value })),
    [],
  );
  const nonVenuOptions = useMemo(
    () =>
      Object.values(PATIENT_NON_VENU_OPTIONS).map((value) => ({
        value,
        label: value,
      })),
    [],
  );
  const createDocument = useCallback(
    ({
      id,
      document,
      type,
    }: {
      id: string;
      document: RcFile;
      type: DocumentType;
    }) => {
      dispatch(createDocumentAsync({ patientID: id, document, type }));
    },
    [dispatch],
  );
  const onOpenDocument = useCallback(
    (documentID: string) => () => {
      dispatch(getPatientDocumentAsync(documentID));
    },
    [dispatch],
  );

  useEffect(
    () => () => {
      dispatch(resetCurrentPatient());
    },
    [dispatch],
  );

  useEffect(
    () => () => {
      if (patientID) {
        dispatch(getPatientDocumentsAsync(patientID));
      }
    },
    [dispatch, patientID],
  );
  if (currentPatientRequestStatus === REQUEST_STATUSES.PENDING) {
    return (
      <div className='flex flex-col items-center justify-center grow'>
        <Spin size='large' />
      </div>
    );
  }

  if (!currentPatient) {
    return (
      <div className='flex flex-col items-center justify-center grow'>
        <Title>Impossible de retrouver ce patient…</Title>
        <Button
          onClick={handleBackClick}
          className='!flex !items-center mb-6'
          icon={<ArrowLeftOutlined />}
        >
          <span>Retour</span>
        </Button>
      </div>
    );
  }

  return (
    <div className='flex flex-col grow'>
      <Button
        size='small'
        type='text'
        onClick={handleBackClick}
        className='!w-24 !inline-flex !items-center mb-2'
        icon={<ArrowLeftOutlined />}
      >
        <span>Retour</span>
      </Button>
      <Title main>Informations du patient</Title>
      <Card className='grid grid-cols-1 gap-4 mb-6 sm:grid-cols-2'>
        {informations.map(({ name, value }) => (
          <InformationItem
            name={name}
            value={value}
            key={name?.toString()}
          />
        ))}
      </Card>
      <div className='flex items-center space-x-2'>
        <Title main>Actions</Title>
        {patchPatientRequestStatus === REQUEST_STATUSES.PENDING && (
          <div className='mb-4'>
            <Spin size='small' />
          </div>
        )}
      </div>
      <div className='flex flex-col space-y-4'>
        <div className='flex flex-row space-x-4'>
          <Select
            placeholder='Changer statut patient'
            defaultValue={currentPatient.rdv_3___statut2}
            onChange={onChangePatientStatus}
            options={statusOptions}
            className='!rounded-full w-52'
          />
          <Select
            placeholder='Vu en téléconsultation'
            defaultValue={
              currentPatient.vu_en_tele_consultation
                ? currentPatient.vu_en_tele_consultation
                : PATIENT_NON_VENU_OPTIONS.Attente
            }
            onChange={onChangePatientNonVenu}
            options={nonVenuOptions}
            className='!rounded-full w-52'
          />
          <Button
            type='primary'
            onClick={setOpenModal(true)}
            className='!inline-flex !items-center'
            icon={<FilePdfOutlined />}
          >
            Rédiger le CERFA
          </Button>
          <a
            href={`mailto:${currentPatient?.email}`}
            target='_blank'
            rel='noreferrer noopener'
          >
            <Button
              className='!inline-flex !items-center'
              icon={<MailOutlined />}
            >
              Envoyer un mail
            </Button>
          </a>
        </div>
        <div className='flex flex-row space-x-4'>
          {patientID && (
            <Upload
              types={[
                DOCUMENT_TYPES.COMPTE_RENDU,
                DOCUMENT_TYPES.COURRIER_MEDECIN,
                DOCUMENT_TYPES.DAP_PPC,
                DOCUMENT_TYPES.DAP_OAM,
                DOCUMENT_TYPES.QUITTANCE,
              ]}
              pending={createDocumentRequestStatus === REQUEST_STATUSES.PENDING}
              createDocument={createDocument}
              patientID={patientID}
            />
          )}
        </div>
        {user && doctor && (
          <CERFAModal
            open={open}
            onCancel={setOpenModal(false)}
            setOpenModal={setOpen}
            user={user}
            doctor={doctor}
            patient={currentPatient}
          />
        )}
      </div>
      <Card className='flex flex-col mt-6 grow min-h-[180px]'>
        <h3 className='mb-2 font-semibold'>Observations</h3>
        <Input.TextArea
          className='grow'
          placeholder='Commentaires libres…'
          onChange={debouncedOnChangeObservations}
          defaultValue={currentPatient.rdv_3___observations_medecin}
        />
      </Card>
      <div className='mt-4'>
        <Title main>Fichiers</Title>
        {documents?.map((document) => (
          <div
            key={document.id}
            className='flex flex-row max-w-lg justify-between bg-primary-light rounded-2xl px-2 py-4 mb-1 mx-2'
          >
            <div className='flex flex-row overflow-hidden text-ellipsis'>
              <SnippetsOutlined className='text-3xl mr-3' />
              <div className='flex flex-col justify-start text-left mt-2 ml-2'>
                <p className='font-semibold text-sm m-0'>
                  {DocumentTypeText[document.type]}
                </p>
                <p className='font-light text-sm m-0'>
                  {dayjs(document.created).format('LL')}
                </p>
              </div>
            </div>
            <Button
              type='link'
              onClick={onOpenDocument(document.id)}
              className='!inline-flex !items-center !justify-center !text-primary !h-12 !text-sm !p-0 !pl-4'
            >
              télécharger <ArrowRightOutlined className='text-xs' />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}
