import {
  CheckOutlined,
  FilePdfOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';
import { Fragment, useCallback, useState } from 'react';
import Card from 'components/layout/Card';
import type { FileStepProps } from './AccountInformationsStepper';

let fileUID = 0;

export default function BankingInformations({
  fileURL = undefined,
  step = 2,
  onValidFile = () => {},
  changeStepToPrevious = () => {},
}: FileStepProps) {
  const [editMode, setEditMode] = useState(!fileURL);
  const [localFile, setLocalFile] = useState<RcFile | null>(null);
  const onSubmit = useCallback(() => {
    onValidFile(step, localFile);
  }, [onValidFile, step, localFile]);
  const onDropFile = useCallback((file: RcFile) => {
    fileUID += 1;
    setLocalFile(file);
    return ''; // Just a wtf return type from antd ;)
  }, []);
  const onRemove = useCallback(() => setLocalFile(null), []);
  const customRequest = useCallback(() => {}, []); // To prevent antd from trying to upload the file
  const onClickEdit = useCallback(() => setEditMode(true), []);

  return (
    <Card>
      {editMode && (
        <Fragment>
          <h3 className='pb-4 text-sm font-semibold'>
            Veuillez nous fournir un RIB pour la facturation des patients
          </h3>
          <Upload.Dragger
            name='ribFile'
            accept='.pdf'
            action={onDropFile}
            onRemove={onRemove}
            multiple={false}
            maxCount={1}
            customRequest={customRequest}
            fileList={
              localFile
                ? [{ uid: `${fileUID}`, name: localFile.name, status: 'done' }]
                : []
            }
          >
            <div className='text-3xl text-primary'>
              <FilePdfOutlined />
            </div>
            <div className='mt-4 mb-2 font-medium'>
              Cliquez ou déposez dans cette zone
            </div>
            <div className='text-xs opacity-80'>
              Merci de télécharger votre RIB au format PDF uniquement.
            </div>
          </Upload.Dragger>
        </Fragment>
      )}
      {!editMode && (
        <Fragment>
          <h3 className='pb-4 text-sm font-semibold'>
            <CheckOutlined className='mr-2 !text-success' />
            Vous avez déjà téléchargé un RIB
          </h3>
          <Button
            onClick={onClickEdit}
            className='!inline-flex !items-center'
          >
            <LinkOutlined />
            Changer mon RIB
          </Button>
        </Fragment>
      )}
      <div className='flex justify-between pt-2 mt-6'>
        <Button onClick={changeStepToPrevious}>Précédent</Button>
        <Button
          type='primary'
          disabled={!localFile && !fileURL}
          onClick={onSubmit}
        >
          Suivant
        </Button>
      </div>
    </Card>
  );
}
