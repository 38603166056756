import { Patient } from 'commons/types/patientsTypes';

export interface Insured {
  paymentCenter: string;
  immatriculation: string;
  key: string;

  nameExt: string;
  immatriculationExt: string;
  keyExt: string;
  addressExt: string;
}

export function initStandalonePatient(): Patient {
  return {
    id: '',
    member: {},
    hubspot_id: '',
    meetings: [],
    mobilephone: '',
    phone: '',
    email: '',
    address: '',
    commentaires_adressse: '',
    city: '',
    zip: '',
    state: '',
    country: '',
    age: '',
    date_of_birth: '',
    dossier: '',
    nic: '',
    hs_object_id: '',
    commentaires_rdv_3: '',
    rdv_3___observations_medecin: '',
    metadata: {
      firstName: '',
      lastName: '',
      status: 'PPC',
      skipToRDV2: false,
    },
    documents: [],
  };
}
export function initInsured(patient: Patient): Insured {
  return {
    paymentCenter: '',
    immatriculation: patient?.nic?.replaceAll(' ', '').slice(0, 13) || '',
    key: patient?.nic?.replaceAll(' ', '').slice(13, 15) || '',

    nameExt: '',
    immatriculationExt: '',
    keyExt: '',
    addressExt: '',
  };
}

export interface Supported {
  sick: boolean;
  ald_choice: string; // 2 choices: yes, no
}

export function initSupported(): Supported {
  return {
    sick: false,
    ald_choice: 'no',
  };
}

export interface Treatment {
  type: string;
  ppcInitialPrescription: string; // 3 choices: init, month, year
  ppcChoice: string; // 3 choices: auto, fixe, double
  ppcPression: string;
  ppcSettings: string;
  ppcInterface: string;
  ppcBrand: string;

  oamDentalExaminationDate: string;
  oamDentalExamination: boolean;
  oamInitialPrescription: string; // 2 choices: init, renew
  oamModel: string;
}

export function initTreatment(type = 'PPC'): Treatment {
  return {
    type,

    ppcInitialPrescription: 'init',
    ppcChoice: 'auto',
    ppcPression: '',
    ppcSettings: '',
    ppcInterface: '',
    ppcBrand: '',

    oamDentalExaminationDate: '',
    oamDentalExamination: false,
    oamInitialPrescription: 'init',
    oamModel: '',
  };
}

export interface Prescription {
  drowsiness: boolean;
  snoring: boolean;
  headaches: boolean;
  tiredness: boolean;
  nocturia: boolean;
  choking: boolean;
  drowsinessHard: boolean;
  cardiovascularComorbidities: boolean;
  cardiovascularComorbiditiesText: string;
  respiratoryComorbidities: boolean;
  respiratoryComorbiditiesText: string;
  polygraphy: boolean;
  polysomnography: boolean;
  iah: string; // 3 choices: hight, mid, low

  PPCRenew4MonthText: string;
  PPCRenew4MonthSymptomatology: string; // 2 choices: 1, 2
  PPCRenew4MonthRefused: boolean;

  PPCRenewYearlyText: string;
  PPCRenewYearlySymptomatology: string; // 2 choices: 1, 2
  PPCRenewYearlyRefused: boolean;
  PPCRefused: boolean;

  OAMRenewYearlyText: string;
  OAMRenewYearlySymptomatology: string; // 2 choices: 1, 2
  OAMRefused: boolean;
}

export function initPrescription(): Prescription {
  return {
    drowsiness: false,
    snoring: false,
    headaches: false,
    tiredness: false,
    nocturia: false,
    choking: false,
    drowsinessHard: false,
    cardiovascularComorbidities: false,
    cardiovascularComorbiditiesText: '',
    respiratoryComorbidities: false,
    respiratoryComorbiditiesText: '',
    polygraphy: true,
    polysomnography: false,
    iah: 'hight',

    PPCRenew4MonthText: '',
    PPCRenew4MonthSymptomatology: '1', // 2 choices: SYM_Oui, SYM_NON
    PPCRenew4MonthRefused: false,

    PPCRenewYearlyText: '',
    PPCRenewYearlySymptomatology: '1', // 2 choices: SYM1_Oui, SYM1_NON
    PPCRenewYearlyRefused: false,
    PPCRefused: false,

    OAMRenewYearlyText: '',
    OAMRenewYearlySymptomatology: '1', // 2 choices: SYM2_Oui, SYM2_NON
    OAMRefused: false,
  };
}
