import { Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { Meeting } from 'commons/types/meetingsTypes';
import { Patient } from 'commons/types/patientsTypes';

interface MeetingListProps {
  className?: string;
  loading?: boolean;
  meetings?: Meeting[];
  sortOrder?: 'ascend' | 'descend';
  onClickMeeting?: (meetingID: string) => void;
  onChangeOrder?: (order: 'ascend' | 'descend') => void;
}

export default function MeetingList({
  className = '',
  loading = false,
  meetings = [],
  sortOrder = 'descend',
  onClickMeeting = () => {},
  onChangeOrder = () => {},
}: MeetingListProps) {
  const onChange = useCallback(
    (
      _p: any,
      _f: any,
      _s: any,
      { action }: { action: 'sort' | 'filter' | 'paginate' },
    ) => {
      if (action === 'sort') {
        onChangeOrder(sortOrder === 'descend' ? 'ascend' : 'descend');
      }
    },
    [onChangeOrder, sortOrder],
  );

  const columns: ColumnsType<Meeting> = useMemo(
    () => [
      {
        key: 'date',
        title: 'RDV',
        dataIndex: 'start_date',
        render: (date: string) => (
          <span>
            {date &&
              dayjs(date, 'YYYY-MM-DD')
                .endOf('day')
                .isAfter(dayjs().startOf('day')) && (
                <Tag color='volcano'>À venir</Tag>
              )}
            {(!date ||
              !dayjs(date, 'YYYY-MM-DD')
                .endOf('day')
                .isAfter(dayjs().startOf('day'))) && (
              <Tag color='purple'>Passé</Tag>
            )}
          </span>
        ),
        width: 80,
      },
      {
        key: 'date',
        title: 'Date',
        dataIndex: 'start_date',
        render: (date: string) =>
          date && dayjs(date, 'YYYY-MM-DD').format('D MMMM'),
        width: 120,
        sortDirections: ['ascend'],
        sortOrder,
        sorter: true,
      },
      {
        key: 'hour',
        title: 'Heure',
        dataIndex: 'start_date',
        width: 60,
        render: (date: string) => date && dayjs(date).format('HH:mm'),
      },
      {
        key: 'name',
        title: 'Nom',
        dataIndex: 'patient',
        render: (patient: Patient) => {
          if (patient.member) {
            return (
              <span className='font-medium'>
                {`${patient.member.firstName || ''} ${
                  patient.member.lastName || ''
                }`}
              </span>
            );
          }
          return (
            <span className='font-medium'>
              {`${patient.metadata.firstName || ''} ${
                patient.metadata.lastName || ''
              }`}
            </span>
          );
        },
        width: 220,
      },
      {
        key: 'zip',
        title: 'CP',
        dataIndex: 'zip',
      },
      {
        key: 'comments',
        title: 'Paiement',
        dataIndex: 'commentaires_rdv_3',
      },
      {
        key: 'status2',
        title: 'Statut',
        dataIndex: 'rdv_3___statut2',
      },
      {
        key: 'non_venu',
        title: 'Non venu',
        dataIndex: 'vu_en_tele_consultation',
      },
      {
        key: 'folder',
        title: 'Dossier',
        dataIndex: 'dossier',
        render: (folder: string) =>
          folder ? (
            <a
              className='underline text-primary'
              href={folder}
              target='_blank'
              rel='noreferrer noopener'
            >
              Lien WeSend
            </a>
          ) : null,
        width: 120,
      },
    ],
    [sortOrder],
  );
  const onRow = useCallback(
    (record: Meeting) => ({
      onClick: () => {
        onClickMeeting(record.patient.id);
      },
      onDoubleClick: () => {},
      onContextMenu: () => {},
      onMouseEnter: () => {},
      onMouseLeave: () => {},
    }),
    [onClickMeeting],
  );
  const rowClassName = useCallback(
    (record: Meeting, index: number) => {
      if (
        index < meetings.length - 1 &&
        record.start_date &&
        meetings[index + 1]?.start_date
      ) {
        const now = dayjs().startOf('day');
        const recordDate = dayjs(record.start_date).endOf('day');

        if (recordDate.isAfter(now)) {
          const nextDate = dayjs(meetings[index + 1].start_date).endOf('day');

          if (nextDate.isBefore(now)) {
            return cx(
              'cursor-pointer relative',
              'after:absolute after:w-full after:h-[2px]',
              'after:bg-primary after:bottom-0 after:left-0',
            );
          }
        }
      }

      return 'cursor-pointer';
    },
    [meetings],
  );

  return (
    <Table
      onChange={onChange}
      rowClassName={rowClassName}
      pagination={false}
      className={className}
      dataSource={meetings}
      columns={columns}
      loading={loading}
      onRow={onRow}
      size='small'
      bordered
      rowKey='id'
    />
  );
}
