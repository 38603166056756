import { Document } from 'commons/types/documentsTypes';
import { Meeting } from 'commons/types/meetingsTypes';
import type { User } from 'stores/user/userTypes';

export const PATIENT_STATUSES = {
  RAS: 'RAS',
  PPC: 'PPC',
  PSG: 'PSG',
  ORL: 'ORL',
} as const;
type PatientStatusKeys = keyof typeof PATIENT_STATUSES;
export type PatientStatus = (typeof PATIENT_STATUSES)[PatientStatusKeys];

export const PATIENT_NON_VENU_OPTIONS = {
  Attente: 'En attente',
  Oui: 'Non venu',
  Non: 'Venu',
} as const;
type PatientNonVenuOptionKeys = keyof typeof PATIENT_NON_VENU_OPTIONS;
export type PatientNonVenuOption =
  (typeof PATIENT_NON_VENU_OPTIONS)[PatientNonVenuOptionKeys];
export interface SmallPatient {
  id: string;
  hubspot_id: string;
  member: User;
  metadata: {
    firstName: string;
    lastName: string;
  };
}

export interface CreatedPatient {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  source: string;
  age: string;
  height: number;
  weight: number;
  postal_code: string;
}

export interface Patient {
  id: string;
  member: User;
  hubspot_id: string;
  meetings: Meeting[];
  mobilephone: string;
  phone: string;
  email: string;
  address: string;
  commentaires_adressse: string;
  city: string;
  zip: string;
  state: string;
  country: string;
  age: string;
  date_of_birth: string;
  dossier: string;
  nic: string;
  hs_object_id: string;
  timer_start?: string;
  timer_end?: string;
  is_psad?: boolean;

  // Move  internal
  commentaires_rdv_3: string;
  rdv_3___observations_medecin: string;
  rdv_3___statut2?: PatientStatus; // PPC / RAS / PSG / ORL
  vu_en_tele_consultation?: PatientNonVenuOption; // Oui / Non / En attente
  rdv_suivi___commentaires?: string;

  metadata: {
    firstName: string;
    lastName: string;
    status: PatientStatus;
    skipToRDV2: boolean;
  };
  documents: Document[];
}
