import { useEffect } from 'react';
import { REQUEST_STATUSES } from 'commons/types/common';
import {
  getHubspotRequestStatus,
  getDoctorHubspotAsync,
  getHubspotToken,
} from 'stores/doctors/doctorsSlice';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { useUser } from 'stores/user/userHooks';
import './HubspotChat.scss';

export default function HubspotConversationsProvider() {
  const [user] = useUser();
  const dispatch = useAppDispatch();
  const hubspotToken = useAppSelector(getHubspotToken);
  const hubspotRequestStatus = useAppSelector(getHubspotRequestStatus);

  useEffect(() => {
    if (user && user.email) {
      dispatch(
        getDoctorHubspotAsync({
          email: user.email,
          firstName: user.firstName || '',
          lastName: user.lastName || '',
        }),
      );
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (hubspotToken && hubspotRequestStatus === REQUEST_STATUSES.SUCCESS) {
      window.hsConversationsSettings = {
        ...window.hsConversationsSettings,
        identificationEmail: user?.email,
        identificationToken: hubspotToken,
      };
      window.HubSpotConversations?.widget.load();
    }
  }, [hubspotRequestStatus, hubspotToken, user?.email]);

  return null;
}

declare global {
  interface Window {
    hsConversationsSettings: Record<string, any>;
    HubSpotConversations: {
      widget: {
        refresh: (openToNewThread?: boolean) => void;
        load: (openToNewThread?: boolean) => void;
      };
    };
  }
}
