import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'commons/types/common';
import { Timeslot } from 'commons/types/timeslotsTypes';
import { useStoreData } from 'stores/hooks';
import { AppDispatch } from 'stores/store';
import {
  getTimeslotsAsync,
  getCurrentTimeslots,
  getCurrentTimeslotsRequestStatus,
  getUpdateOrCreateTimeslotsRequestStatus,
  updateOrCreateTimeslotAsync,
} from './timeslotsSlice';

export function useCurrentTimeslots(doctorID?: string) {
  return useStoreData(
    getCurrentTimeslots,
    getCurrentTimeslotsRequestStatus,
    getTimeslotsAsync,
    doctorID,
  );
}

export function useUpdateOrCreateTimeslot(): [
  (timeslotData: Timeslot) => void,
  RequestStatus,
] {
  const dispatch = useDispatch<AppDispatch>();
  const requestStatus = useSelector(getUpdateOrCreateTimeslotsRequestStatus);

  const updateOrCreate = useCallback(
    (timeslotData: Timeslot) => {
      dispatch(updateOrCreateTimeslotAsync(timeslotData));
    },
    [dispatch],
  );

  return [updateOrCreate, requestStatus];
}
