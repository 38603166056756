// FILLME
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus, REQUEST_STATUSES } from 'commons/types/common';
import { Timeslot, initDefaultTimeslot } from 'commons/types/timeslotsTypes';
import type { RootState } from 'stores/store';
import { getTimeslots, updateOrCreateTimeslot } from './timeslotsClient';

export interface TimeslotsState {
  current: Timeslot;
  currentRequestStatus?: RequestStatus;
  updateOrCreateRequestStatus?: RequestStatus;
}

const initialState: TimeslotsState = {
  current: initDefaultTimeslot(),
};

export const getTimeslotsAsync = createAsyncThunk(
  'timeslots',
  (doctorID?: string) => getTimeslots(doctorID),
);

export const updateOrCreateTimeslotAsync = createAsyncThunk(
  'timeslots/updateOrCreate',
  (timeslotData: Timeslot) => updateOrCreateTimeslot(timeslotData),
);

export const timeslotsSlice = createSlice({
  name: 'timeslots',
  initialState,
  reducers: {
    resetCurrentTimeslots: (state) => {
      state.current = initDefaultTimeslot();
      delete state.currentRequestStatus;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTimeslotsAsync.pending, (state) => {
        state.currentRequestStatus = REQUEST_STATUSES.PENDING;
      })
      .addCase(getTimeslotsAsync.fulfilled, (state, { payload }) => {
        state.currentRequestStatus = REQUEST_STATUSES.SUCCESS;
        state.current = payload;
      })
      .addCase(getTimeslotsAsync.rejected, (state) => {
        state.currentRequestStatus = REQUEST_STATUSES.ERROR;
      })
      .addCase(updateOrCreateTimeslotAsync.pending, (state, { meta }) => {
        state.updateOrCreateRequestStatus = REQUEST_STATUSES.PENDING;
        state.current = meta.arg;
      })
      .addCase(updateOrCreateTimeslotAsync.fulfilled, (state, { payload }) => {
        state.updateOrCreateRequestStatus = REQUEST_STATUSES.SUCCESS;
        state.current = payload;
      })
      .addCase(updateOrCreateTimeslotAsync.rejected, (state) => {
        state.updateOrCreateRequestStatus = REQUEST_STATUSES.ERROR;
      });
  },
});

export const getUpdateOrCreateTimeslotsRequestStatus = (state: RootState) =>
  state.timeslots.updateOrCreateRequestStatus;
export const getUpdateOrCreateTimeslots = (state: RootState) =>
  state.timeslots.current;

export const { resetCurrentTimeslots } = timeslotsSlice.actions;
export const getCurrentTimeslots = (state: RootState) =>
  state.timeslots.current;
export const getCurrentTimeslotsRequestStatus = (state: RootState) =>
  state.timeslots.currentRequestStatus;

export default timeslotsSlice.reducer;
