import {
  MenuOutlined,
  VideoCameraOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import cx from 'classnames';
import { useCallback, useMemo } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Logo from 'commons/assets/Logo';
import HubspotChat from 'components/hubspotchat/HubspotChat';
import { signoutAsync } from 'stores/auth/authSlice';
import { useAppDispatch } from 'stores/hooks';

const linkItems = [
  {
    url: '/',
    content: 'Agenda',
  },
  {
    url: '/patients',
    content: 'Mes patients',
    isSelected: (pathname: string) => pathname.includes('/patients'),
  },
  {
    url: '/CERFA',
    content: 'CERFA',
    isSelected: (pathname: string) => pathname.includes('/CERFA'),
  },
  {
    url: '/account',
    content: 'Mon compte',
  },
];

export default function MainLayout() {
  const dispatch = useAppDispatch();
  const currentPathname = useLocation().pathname;

  const containerClassName = cx(
    'flex',
    'flex-col',
    'min-h-screen',
    'min-w-screen',
    'bg-background',
  );
  const onClickMenuItem = useCallback(
    ({ key }: { key: string }) => {
      if (key === 'logout') {
        dispatch(signoutAsync());
      }
    },
    [dispatch],
  );
  const menuProps: MenuProps = useMemo(
    () => ({
      items: [
        {
          key: 'logout',
          label: 'Se deconnecter',
          icon: <LogoutOutlined />,
          onClick: onClickMenuItem,
        },
      ],
    }),
    [onClickMenuItem],
  );

  return (
    <div className={containerClassName}>
      <header className='fixed z-10 w-full bg-background-z-10 drop-shadow-xl'>
        <div className='flex justify-between w-full py-3 mx-auto max-w-screen-2xl px-14'>
          <Link
            className='mr-4'
            to='/'
          >
            <Logo width={143} />
          </Link>
          <div className='flex items-center justify-end grow'>
            {linkItems.map(
              ({
                url,
                content,
                isSelected = (pathname: string, itemUrl: string) =>
                  pathname === itemUrl,
              }) => (
                <Link
                  to={url}
                  key={url}
                  className={cx(
                    'lg:text-xl text-lg mr-5 lg:mr-10 hidden md:block',
                    { 'font-bold': isSelected(currentPathname, url) },
                  )}
                >
                  {content}
                </Link>
              ),
            )}
            <a
              href='https://hapni.doxy.me/sign-in'
              target='_blank'
              rel='noreferrer noopener'
              className={cx(
                'flex lg:h-[40px] items-center text-white rounded-full bg-gradient relative',
                'lg:pr-4 pr-0 lg:pl-12 pl-0',
              )}
            >
              <span
                className={cx(
                  'w-[42px] h-[42px] inline-flex flex-col justify-center items-center',
                  '-left-px -top-px lg:border-2 border-white rounded-full p-0.5 lg:absolute',
                )}
              >
                <VideoCameraOutlined />
              </span>
              <span className='hidden lg:inline-block'>Cabinet virtuel</span>
            </a>
            <div className='ml-4 md:hidden'>
              <Dropdown
                trigger={['click']}
                menu={menuProps}
              >
                <MenuOutlined className='text-2xl' />
              </Dropdown>
            </div>
          </div>
        </div>
      </header>
      <main className='flex flex-col w-full pt-[134px] pb-8 mx-auto max-w-screen-2xl px-14 grow'>
        <Outlet />
      </main>
      <HubspotChat />
    </div>
  );
}
