export const ROLES = {
  ACTIVE: 'active',
  STAFF: 'staff',
  DOCTOR: 'doctor',
  NURSE: 'nurse',
  DOCTOR_ACTIVATED: 'doctor_activated',
} as const;

type RolesKeys = keyof typeof ROLES;
export type Roles = (typeof ROLES)[RolesKeys];
