import { Button } from 'antd';
import cx from 'classnames';
import { useCallback, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Card from 'components/layout/Card';
import type { FileStepProps } from './AccountInformationsStepper';

export default function SignatureStep({
  fileURL = undefined,
  step = 3,
  onValidFile = () => {},
  changeStepToPrevious = () => {},
}: FileStepProps) {
  const canvas = useRef<SignatureCanvas | null>(null);
  const [showBackground, setShowBackground] = useState(Boolean(fileURL));
  const [loading, setLoading] = useState(false);
  const [drew, setDrew] = useState(false);
  const onEnd = useCallback(() => setDrew(true), []);
  const onBegin = useCallback(() => setShowBackground(false), []);
  const onClickClear = useCallback(() => {
    if (canvas.current) {
      canvas.current.clear();
      setDrew(false);
      setShowBackground(false);
    }
  }, []);
  const onSubmit = useCallback(async () => {
    if (fileURL && showBackground) {
      onValidFile(step, null);
      return;
    }
    if (canvas.current) {
      setLoading(true);
      const base64 = canvas.current.toDataURL('image/png');
      const response = await fetch(base64);
      const blob = await response.blob();
      onValidFile(
        step,
        new File([blob], 'signature_file.png', { type: 'image/png' }),
      );
      setLoading(false);
    }
  }, [onValidFile, step, fileURL, showBackground]);
  const onClickReset = useCallback(() => {
    setShowBackground(true);
    onClickClear();
  }, [onClickClear]);

  return (
    <Card>
      <h3 className='pb-4 text-sm font-semibold'>
        Nous avons besoin de votre signature pour rediger les CERFA
      </h3>
      <div className='flex flex-col'>
        <SignatureCanvas
          onEnd={onEnd}
          onBegin={onBegin}
          penColor='#1E2248'
          clearOnResize={false}
          backgroundColor='transparent'
          canvasProps={{
            width: 380,
            height: 100,
            className: cx(
              'border border-transparent hover:border hover:border-primary box-border',
              'bg-no-repeat bg-contain bg-center rounded-md bg-white overflow-hidden',
            ),
            style:
              showBackground && fileURL
                ? {
                    backgroundImage: `url(${fileURL})`,
                  }
                : {},
          }}
          ref={canvas}
        />
        <div className='flex'>
          {fileURL && !showBackground && (
            <button
              className='text-xs font-medium !inline-flex !items-center mt-1 mr-1'
              onClick={onClickReset}
              disabled={loading}
            >
              Annuler modifications
            </button>
          )}
          <div className='grow' />
          <button
            className='text-xs font-medium !inline-flex !items-center mt-1 mr-1'
            onClick={onClickClear}
            disabled={loading}
          >
            Effacer
          </button>
        </div>
      </div>
      <div className='flex justify-between pt-2 mt-6'>
        <Button onClick={changeStepToPrevious}>Précédent</Button>
        <Button
          type='primary'
          disabled={(!drew || loading) && !(fileURL && showBackground)}
          onClick={onSubmit}
          loading={loading}
        >
          Suivant
        </Button>
      </div>
    </Card>
  );
}
