import { animated, useChain, useSpring, useSpringRef } from '@react-spring/web';
import { Steps } from 'antd';
import cx from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import usePrevious from 'commons/hooks/usePrevious';
import { REQUEST_STATUSES } from 'commons/types/common';
import { Doctor } from 'commons/types/doctorsTypes';
import {
  updateDoctorAsync,
  getUpdateDoctorRequestStatus,
} from 'stores/doctors/doctorsSlice';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import type { User } from 'stores/user/userTypes';
import AdministrativeStep from './AdministrativeStep';
import BankingInformations from './BankingInformations';
import BillingStep from './BillingStep';
import ContactStep from './ContactStep';
import SignatureStep from './SignatureStep';

export interface StepProps {
  user: User;
  doctor: Doctor;
  step?: number;
  onValidStep: (
    step: number,
    { doctor, user }: { doctor: Doctor; user: User },
  ) => void;
  changeStepToPrevious?: () => void;
}

export interface FileStepProps {
  step?: number;
  fileURL?: string;
  onValidFile?: (step: number, file: File | null) => void;
  changeStepToPrevious?: () => void;
}

const STEPS = [
  {
    title: 'Contact',
  },
  {
    title: 'Facturation patients',
  },
  {
    title: 'Informations bancaires',
  },
  {
    title: 'Signature CERFA',
  },
  {
    title: 'Informations CERFA',
  },
];

export default function AccountInformationsStepper({
  doctor,
  user,
}: {
  doctor: Doctor;
  user: User;
}) {
  const dispatch = useAppDispatch();
  const patchUserRequestStatus = useAppSelector(getUpdateDoctorRequestStatus);
  const [currentStep, setCurrentStep] = useState(0);
  const previousStep = usePrevious(currentStep);
  const [localDoctor, setLocalDoctor] = useState<Doctor>({});
  const [localUser, setLocalUser] = useState<User>({});
  const [ribFile, setRibFile] = useState<File | null>(null);
  const [signatureFile, setSignatureFile] = useState<File | null>(null);
  const onSaveDoctor = useCallback(
    (newUser: { doctor: Doctor; user: User }) => {
      dispatch(
        updateDoctorAsync({
          ...newUser,
          id: doctor?.id || '',
          ribFile,
          signatureFile,
        }),
      );
    },
    [doctor, ribFile, signatureFile, dispatch],
  );
  const changeStepToPrevious = useCallback(
    () => setCurrentStep(Math.max(0, currentStep - 1)),
    [currentStep],
  );
  const onValidStep = useCallback(
    (step: number, newUser: { doctor: Doctor; user: User }) => {
      if (step === STEPS.length - 1) {
        onSaveDoctor(newUser);
        return;
      }

      setLocalDoctor(newUser.doctor);
      setLocalUser(newUser.user);
      setCurrentStep(step + 1);
    },
    [onSaveDoctor],
  );
  const onValidFile = useCallback(
    (setter: (file: File | null) => void) =>
      (step: number, file: File | null) => {
        setter(file);
        setCurrentStep(step + 1);
      },
    [],
  );
  const firstStepRef = useSpringRef();
  const { height: firstStepHeight, ...firstStepRest } = useSpring({
    ref: firstStepRef,
    from: {
      height: previousStep === undefined || previousStep === 0 ? '100%' : '0%',
    },
    to: { height: currentStep === 0 ? '100%' : '0%' },
  });
  const secondStepRef = useSpringRef();
  const { height: secondStepHeight, ...secondStepRest } = useSpring({
    ref: secondStepRef,
    from: { height: previousStep === 1 ? '100%' : '0%' },
    to: { height: currentStep === 1 ? '100%' : '0%' },
  });
  const thirdStepRef = useSpringRef();
  const { height: thirdStepHeight, ...thirdStepRest } = useSpring({
    ref: thirdStepRef,
    from: { height: previousStep === 2 ? '100%' : '0%' },
    to: { height: currentStep === 2 ? '100%' : '0%' },
  });
  const fourthStepRef = useSpringRef();
  const { height: fourthStepHeight, ...fourthStepRest } = useSpring({
    ref: fourthStepRef,
    from: { height: previousStep === 3 ? '100%' : '0%' },
    to: { height: currentStep === 3 ? '100%' : '0%' },
  });
  const fifthStepRef = useSpringRef();
  const { height: fifthStepHeight, ...fifthStepRest } = useSpring({
    ref: fifthStepRef,
    from: { height: previousStep === 4 ? '100%' : '0%' },
    to: { height: currentStep === 4 ? '100%' : '0%' },
  });
  const chainOder = useMemo(() => {
    if (previousStep !== undefined && previousStep > currentStep) {
      switch (currentStep) {
        case 0:
          return [secondStepRef, firstStepRef];
        case 1:
          return [thirdStepRef, secondStepRef];
        case 2:
          return [fourthStepRef, thirdStepRef];
        case 3:
          return [fifthStepRef, fourthStepRef];
        default:
          return [];
      }
    }

    switch (currentStep) {
      case 1:
        return [firstStepRef, secondStepRef];
      case 2:
        return [secondStepRef, thirdStepRef];
      case 3:
        return [thirdStepRef, fourthStepRef];
      case 4:
        return [fourthStepRef, fifthStepRef];
      default:
        return [];
    }
  }, [
    previousStep,
    currentStep,
    firstStepRef,
    secondStepRef,
    thirdStepRef,
    fourthStepRef,
    fifthStepRef,
  ]);

  useChain(chainOder, [0, 0.6]);

  useEffect(() => {
    if (user) {
      setLocalUser(user);
    }
    if (doctor) {
      setLocalDoctor(doctor);
    }
  }, [user, doctor]);

  return (
    <div
      className={cx(
        'flex flex-col items-center md:items-start w-full',
        'max-w-screen-md pt-8 mx-auto md:flex-row px-6 md:px-14',
      )}
    >
      <aside className='flex-shrink-0 pt-6 mr-8'>
        <h1 className='mb-8 font-semibold'>Vos informations</h1>
        <Steps
          direction='vertical'
          size='small'
          current={currentStep}
          items={STEPS}
        />
      </aside>
      {localDoctor.id && localUser.id && (
        <main className='h-[460px] grow overflow-hidden'>
          <animated.div
            className='overflow-hidden grow'
            style={{ ...firstStepRest, height: firstStepHeight }}
          >
            <ContactStep
              user={localUser}
              doctor={localDoctor}
              onValidStep={onValidStep}
            />
          </animated.div>
          <animated.div
            className='overflow-hidden'
            style={{ ...secondStepRest, height: secondStepHeight }}
          >
            <BillingStep
              user={localUser}
              doctor={localDoctor}
              onValidStep={onValidStep}
              changeStepToPrevious={changeStepToPrevious}
            />
          </animated.div>
          <animated.div
            className='overflow-hidden'
            style={{ ...thirdStepRest, height: thirdStepHeight }}
          >
            <BankingInformations
              onValidFile={onValidFile(setRibFile)}
              changeStepToPrevious={changeStepToPrevious}
              fileURL={doctor.rib}
            />
          </animated.div>
          <animated.div
            className='overflow-hidden'
            style={{ ...fourthStepRest, height: fourthStepHeight }}
          >
            <SignatureStep
              onValidFile={onValidFile(setSignatureFile)}
              changeStepToPrevious={changeStepToPrevious}
              fileURL={doctor.signature}
            />
          </animated.div>
          <animated.div
            className='overflow-hidden'
            style={{ ...fifthStepRest, height: fifthStepHeight }}
          >
            <AdministrativeStep
              user={localUser}
              doctor={localDoctor}
              onValidStep={onValidStep}
              loading={patchUserRequestStatus === REQUEST_STATUSES.PENDING}
              changeStepToPrevious={changeStepToPrevious}
            />
          </animated.div>
        </main>
      )}
    </div>
  );
}
