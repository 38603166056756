import { ReactNode } from 'react';

interface InformationItemProps {
  name: ReactNode;
  value: ReactNode;
}

export default function InformationItem({ name, value }: InformationItemProps) {
  return (
    <div>
      <h3 className='mb-2 font-semibold'>{name}</h3>
      <div className='pl-1'>{value}</div>
    </div>
  );
}
