import cx from 'classnames';
import { Outlet } from 'react-router-dom';
import Logo from 'commons/assets/Logo';

export default function LightLayout() {
  const containerClassName = cx(
    'flex',
    'flex-col',
    'items-center',
    'min-h-screen',
    'bg-background',
    'sm:p-16 sm:pt-32 p-8',
  );

  return (
    <div className={containerClassName}>
      <div className='mb-2'>
        <Logo width={143} />
      </div>
      <main className='flex flex-col w-full grow'>
        <Outlet />
      </main>
    </div>
  );
}
