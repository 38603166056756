import Request from 'commons/libs/requests';
import { Timeslot } from 'commons/types/timeslotsTypes';

export async function getTimeslots(doctorID?: string) {
  const params = new URLSearchParams();

  if (doctorID) {
    params.append('doctor_id', doctorID);
  }

  const response = await Request.get('/api/timeslots/', { params });
  return response.data;
}

export async function updateOrCreateTimeslot(timeslotData: Timeslot) {
  const response = await Request.post('/api/timeslots/', timeslotData);
  return response.data;
}
