import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus, REQUEST_STATUSES } from 'commons/types/common';
import type { RootState } from 'stores/store';
import fetchUser from './userClient';
import type { User } from './userTypes';

export interface UserState {
  user?: User;
  userRequestStatus?: RequestStatus;
}

const initialState: UserState = {};

export const getUserAsync = createAsyncThunk('user/get', () => fetchUser());
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUser: (state) => {
      delete state.user;
      delete state.userRequestStatus;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAsync.pending, (state) => {
        state.userRequestStatus = REQUEST_STATUSES.PENDING;
      })
      .addCase(getUserAsync.fulfilled, (state, { payload }) => {
        state.userRequestStatus = REQUEST_STATUSES.SUCCESS;
        state.user = payload;
      })
      .addCase(getUserAsync.rejected, (state) => {
        state.userRequestStatus = REQUEST_STATUSES.ERROR;
      });
  },
});

export const { resetUser } = userSlice.actions;
export const getUser = (state: RootState) => state.user.user;
export const getUserRequestStatus = (state: RootState) =>
  state.user.userRequestStatus;
export default userSlice.reducer;
