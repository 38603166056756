import {
  ActionCreatorWithoutPayload,
  AsyncThunk,
  Dispatch,
} from '@reduxjs/toolkit';
import { useCallback, useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import usePrevious from 'commons/hooks/usePrevious';
import { RequestStatus, REQUEST_STATUSES } from 'commons/types/common';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

interface AsyncThunkConfig {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
}

interface IUseGlobalStoreData<T, U = void> {
  dataGetter: (state: RootState) => T;
  requestStatusGetter: (state: RootState) => RequestStatus;
  thunk: AsyncThunk<any, U, AsyncThunkConfig>;
  params?: U;
  paramsChanged?: boolean;
}

export function useGlobalStoreData<T, U = void>({
  dataGetter,
  requestStatusGetter,
  thunk,
  params,
  paramsChanged,
}: IUseGlobalStoreData<T, U>): [T, RequestStatus] {
  const dispatch = useAppDispatch();
  const data = useAppSelector(dataGetter);
  const requestStatus = useAppSelector(requestStatusGetter);
  const previousParams = usePrevious(params);
  const shouldRefetch =
    paramsChanged === undefined
      ? !isEqual(params, previousParams)
      : paramsChanged;

  useEffect(() => {
    if (requestStatus === REQUEST_STATUSES.NOT_INITIALIZED || shouldRefetch) {
      dispatch(thunk(params as U));
    }
  }, [requestStatus, params, thunk, shouldRefetch, dispatch]);

  return [data, requestStatus];
}

interface IUseLocalStoreData<T, U = void> {
  dataGetter: (state: RootState) => T;
  requestStatusGetter: (state: RootState) => RequestStatus;
  thunk: AsyncThunk<any, U, AsyncThunkConfig>;
  reset?: ActionCreatorWithoutPayload;
  params?: U | null;
}

export function useLocalStoreData<T, U = void>({
  dataGetter,
  requestStatusGetter,
  thunk,
  reset,
  params = null,
}: IUseLocalStoreData<T, U>): [T, RequestStatus] {
  const dispatch = useAppDispatch();
  const data = useAppSelector(dataGetter);
  const requestStatus = useAppSelector(requestStatusGetter);
  const previousParams = usePrevious(params);
  const paramsChanged = !isEqual(params, previousParams);

  useEffect(() => {
    if (paramsChanged) {
      dispatch(thunk(params as U));
    }
  }, [params, thunk, paramsChanged, dispatch]);

  useEffect(
    () => () => {
      if (reset) {
        dispatch(reset());
      }
    },
    [reset, dispatch],
  );

  return [data, requestStatus];
}

interface IUseCrudAction<U = void> {
  requestStatusGetter: (state: RootState) => RequestStatus;
  thunk: AsyncThunk<any, U, AsyncThunkConfig>;
}

export function useCrudAction<U = void>({
  requestStatusGetter,
  thunk,
}: IUseCrudAction<U>): [(params: U) => void, RequestStatus] {
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(requestStatusGetter);
  const crudAction = useCallback(
    (params?: U) => {
      dispatch(thunk(params as U));
    },
    [dispatch, thunk],
  );

  return [crudAction, requestStatus];
}

export function useStoreData<T, U = void>(
  dataGetter: (state: RootState) => T,
  requestStatusGetter: (state: RootState) => RequestStatus,
  thunk: AsyncThunk<any, U, AsyncThunkConfig>,
  params?: U,
): [T, RequestStatus] {
  const dispatch = useAppDispatch();
  const data = useAppSelector(dataGetter);
  const requestStatus = useAppSelector(requestStatusGetter);
  const previousParams = usePrevious(params);
  const paramsChanged = !isEqual(params, previousParams);

  useEffect(() => {
    if (requestStatus === REQUEST_STATUSES.NOT_INITIALIZED || paramsChanged) {
      dispatch(thunk(params as U));
    }
  }, [requestStatus, params, thunk, paramsChanged, dispatch]);

  return [data, requestStatus];
}
