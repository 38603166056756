import {
  Row,
  Col,
  Form,
  Radio,
  Input,
  Checkbox,
  Typography,
  FormInstance,
} from 'antd';
import { Fragment, FormEvent, useCallback } from 'react';

const { Title } = Typography;

interface CERFAPrescriptionProps {
  form: FormInstance;
}
export default function CERFAPrescription({ form }: CERFAPrescriptionProps) {
  const formType = Form.useWatch(['treatment', 'type'], form);
  const cardiovascularValue = Form.useWatch(
    ['prescription', 'cardiovascularComorbidities'],
    form,
  );
  const respiValue = Form.useWatch(
    ['prescription', 'respiratoryComorbidities'],
    form,
  );
  const preventEnterDefaultBehavior = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => event.preventDefault(),
    [],
  );

  return (
    <div>
      <Row gutter={8}>
        <Title level={3}>Prescription initiale</Title>
      </Row>
      <Row gutter={8}>
        <Title level={4}>Symptômes cliniques et pathologies :</Title>
      </Row>
      <Row gutter={8}>
        <Form.Item
          valuePropName='checked'
          name={['prescription', 'drowsiness']}
          style={{ margin: 0 }}
        >
          <Checkbox>Somnolence diurne</Checkbox>
        </Form.Item>
      </Row>

      <Row gutter={8}>
        <Form.Item
          valuePropName='checked'
          name={['prescription', 'snoring']}
          style={{ margin: 0 }}
        >
          <Checkbox>Ronflements sévères et quotidiens </Checkbox>
        </Form.Item>
      </Row>
      <Row gutter={8}>
        <Form.Item
          valuePropName='checked'
          name={['prescription', 'headaches']}
          style={{ margin: 0 }}
        >
          <Checkbox>Céphalées matinales</Checkbox>
        </Form.Item>
      </Row>
      <Row gutter={8}>
        <Form.Item
          valuePropName='checked'
          name={['prescription', 'tiredness']}
          style={{ margin: 0 }}
        >
          <Checkbox>Fatigue diurne</Checkbox>
        </Form.Item>
      </Row>

      <Row gutter={8}>
        <Form.Item
          valuePropName='checked'
          name={['prescription', 'nocturia']}
          style={{ margin: 0 }}
        >
          <Checkbox>Nycturie</Checkbox>
        </Form.Item>
      </Row>

      <Row gutter={8}>
        <Form.Item
          valuePropName='checked'
          name={['prescription', 'choking']}
          style={{ margin: 0 }}
        >
          <Checkbox>
            Sensation d’étouffement ou de suffocation pendant le sommeil
          </Checkbox>
        </Form.Item>
      </Row>

      <Row gutter={8}>
        <Form.Item
          valuePropName='checked'
          name={['prescription', 'drowsinessHard']}
          style={{ margin: 0 }}
        >
          <Checkbox>Somnolence diurne sévère et/ou risque accidentel</Checkbox>
        </Form.Item>
      </Row>

      <Row gutter={8}>
        <Form.Item
          valuePropName='checked'
          name={['prescription', 'cardiovascularComorbidities']}
          style={{ marginBottom: 0 }}
        >
          <Checkbox>Comorbidité cardio-vasculaire associée grave</Checkbox>
        </Form.Item>
      </Row>
      {cardiovascularValue && (
        <Row gutter={8}>
          <Form.Item
            name={['prescription', 'cardiovascularComorbiditiesText']}
            rules={[{ required: cardiovascularValue }]}
            style={{ marginBottom: 0 }}
          >
            <Input.TextArea
              name='cardiovascularComorbiditiesText'
              rows={3}
              cols={64}
              placeholder='À préciser'
              onPressEnter={preventEnterDefaultBehavior}
              maxLength={124}
            />
          </Form.Item>
        </Row>
      )}
      <Row gutter={8}>
        <Form.Item
          valuePropName='checked'
          name={['prescription', 'respiratoryComorbidities']}
          style={{ marginBottom: 0 }}
        >
          <Checkbox>Comorbidité respiratoire associée grave</Checkbox>
        </Form.Item>
      </Row>
      {respiValue && (
        <Row gutter={8}>
          <Form.Item
            name={['prescription', 'respiratoryComorbiditiesText']}
            rules={[{ required: respiValue }]}
            style={{ marginBottom: 0 }}
          >
            <Input.TextArea
              name='respiratoryComorbiditiesText'
              rows={3}
              cols={64}
              placeholder='À préciser'
              onPressEnter={preventEnterDefaultBehavior}
              maxLength={124}
            />
          </Form.Item>
        </Row>
      )}
      <Row gutter={8}>
        <Title level={4}>Examen réalisé</Title>
      </Row>
      <Row gutter={8}>
        <Form.Item
          valuePropName='checked'
          name={['prescription', 'polygraphy']}
          style={{ marginBottom: 0 }}
        >
          <Checkbox>Polygraphie</Checkbox>
        </Form.Item>
      </Row>
      <Row gutter={8}>
        <Form.Item
          valuePropName='checked'
          name={['prescription', 'polysomnography']}
        >
          <Checkbox>Polysomnographie</Checkbox>
        </Form.Item>
      </Row>

      <Row gutter={8}>
        <span className='ant-form-text'>
          Renseigner l&apos;indice d&apos;apnées–hypopnées (IHA) par heure
          correspondant à l’examen réalisé:
        </span>
      </Row>
      <Row gutter={8}>
        <Form.Item name={['prescription', 'iah']}>
          <Radio.Group name='iah'>
            <Radio value='hight'>IAH ≥ 30/h</Radio>
            <Radio value='mid'>{'IAH<30/h et ≥ 15/h'}</Radio>
            <Radio value='low'>{'IAH <15/h'}</Radio>
          </Radio.Group>
        </Form.Item>
      </Row>
      {formType === 'PPC' && (
        <Fragment>
          <Row gutter={8}>
            <Title level={4}>
              Prescription du 1er renouvellement à 4 mois après la prescription
              initiale du traitement par PPC
            </Title>
          </Row>
          <Row gutter={8}>
            <Form.Item
              label='Indiquer l’indice d’AH/h sous traitement'
              name={['prescription', 'PPCRenew4MonthText']}
              style={{ width: 512 }}
            >
              <Input
                name='PPCRenew4MonthText'
                maxLength={53}
              />
            </Form.Item>
          </Row>
          <Row gutter={8}>
            <span className='ant-form-text'>
              Amélioration de la symptomatologie
            </span>
          </Row>
          <Row gutter={8}>
            <Form.Item
              name={['prescription', 'PPCRenew4MonthSymptomatology']}
              style={{ margin: 0 }}
            >
              <Radio.Group name='PPCRenew4MonthSymptomatology'>
                <Radio value='1'>Oui</Radio>
                <Radio value='2'>Non</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>
          <Row gutter={8}>
            <Form.Item
              valuePropName='checked'
              name={['prescription', 'PPCRenew4MonthRefused']}
            >
              <Checkbox>Refus du relevé d’observance par le patient</Checkbox>
            </Form.Item>
          </Row>
        </Fragment>
      )}
      {formType === 'PPC' && (
        <Fragment>
          <Row gutter={8}>
            <Title level={4}>
              Prescription de renouvellement annuel du traitement par PPC
            </Title>
          </Row>
          <Row gutter={8}>
            <Form.Item
              label='Indiquer l’indice d’AH/h sous traitement'
              name={['prescription', 'PPCRenewYearlyText']}
              style={{ width: 512 }}
            >
              <Input
                name='PPCRenewYearlyText'
                maxLength={53}
              />
            </Form.Item>
          </Row>
          <Row gutter={8}>
            <span className='ant-form-text'>
              Amélioration de la symptomatologie
            </span>
          </Row>
          <Row gutter={8}>
            <Form.Item
              name={['prescription', 'PPCRenewYearlySymptomatology']}
              style={{ margin: 0 }}
            >
              <Radio.Group name='PPCRenewYearlySymptomatology'>
                <Radio value='1'>Oui</Radio>
                <Radio value='2'>Non</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>
          <Row gutter={8}>
            <Form.Item
              valuePropName='checked'
              name={['prescription', 'PPCRenewYearlyRefused']}
            >
              <Checkbox>Refus du relevé d’observance par le patient</Checkbox>
            </Form.Item>
          </Row>
        </Fragment>
      )}
      {formType === 'OAM' && (
        <Fragment>
          <Row gutter={8}>
            <Title level={4}>
              Prescription de renouvellement du traitement par OAM
            </Title>
          </Row>
          <Row gutter={8}>
            <Form.Item
              label='Indiquer l’indice d’AH/h sous traitement'
              name={['prescription', 'OAMRenewYearlyText']}
              style={{ width: 512 }}
            >
              <Input
                name='OAMRenewYearlyText'
                maxLength={53}
              />
            </Form.Item>
          </Row>

          <Row gutter={8}>
            <span className='ant-form-text'>
              Amélioration de la symptomatologie
            </span>
          </Row>
          <Row gutter={8}>
            <Form.Item name={['prescription', 'OAMRenewYearlySymptomatology']}>
              <Radio.Group name='OAMRenewYearlySymptomatology'>
                <Radio value='1'>Oui</Radio>
                <Radio value='2'>Non</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>
        </Fragment>
      )}
      <Row gutter={8}>
        <Title level={4}>Refus ou intolérance</Title>
      </Row>
      <Row gutter={8}>
        <Col>
          <Form.Item
            valuePropName='checked'
            name={['prescription', 'PPCRefused']}
          >
            <Checkbox>de la PPC</Checkbox>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            valuePropName='checked'
            name={['prescription', 'OAMRefused']}
          >
            <Checkbox>de l’OAM</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
