import { StrictMode } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from 'react-router-dom';
import LightLayout from 'commons/components/layout/LightLayout';
import ContentSpiner from 'commons/components/spinner/ContentSpiner';
import useTailwindStyleTagOrder from 'commons/hooks/useTailwindStyleTagOrder';
import { AuthStatus, AUTH_STATUSES } from 'commons/types/common';
import { ROLES } from 'commons/types/usersTypes';
import MainLayout from 'components/layout/MainLayout';
import Account from 'routes/account/Account';
import Agenda from 'routes/agenda/Agenda';
import NewAccount from 'routes/auth/NewAccount';
import NotFound from 'routes/notfound/NotFound';
import Patient from 'routes/patients/Patient';
import Patients from 'routes/patients/Patients';
import { useAuthStatus } from 'stores/auth/authHooks';
import { useUser } from 'stores/user/userHooks';
import CERFAPage from 'routes/CERFA/CERFA';

export default function Router() {
  const [authStatus] = useAuthStatus();

  useTailwindStyleTagOrder();

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<StrictModeProvider />}>
          <Route element={<MainLayout />}>
            <Route
              element={
                <ProtectedRoute
                  authStatus={authStatus}
                  roles={[ROLES.DOCTOR_ACTIVATED]}
                  redirect='new-account'
                />
              }
            >
              <Route
                element={<Agenda />}
                index
              />
              <Route path='patients'>
                <Route
                  index
                  element={<Patients />}
                />
                <Route
                  element={<Patient />}
                  path=':patientID'
                />
              </Route>
              <Route
                element={<Account />}
                path='account'
              />
              <Route
                element={<CERFAPage />}
                path='CERFA'
              />
            </Route>
          </Route>
          <Route element={<LightLayout />}>
            <Route element={<ProtectedRoute authStatus={authStatus} />}>
              <Route
                element={<NewAccount />}
                path='new-account'
              />
              <Route
                element={<NotFound />}
                path='*'
              />
            </Route>
          </Route>
        </Route>
        <Route element={<AnonymousRoute authStatus={authStatus} />} />
      </Routes>
    </BrowserRouter>
  );
}

export function generateAuthURL(host: string) {
  const currentURL = new URL(window.location.href);

  if (currentURL.searchParams.has('state')) {
    currentURL.searchParams.delete('state');
  }

  return `${host}?state=${currentURL.toString()}`;
}

interface RouteProps {
  authStatus: AuthStatus;
  redirect?: string;
}
interface ProtectedRouteProps extends RouteProps {
  roles?: string[];
}

function ProtectedRoute({
  authStatus,
  roles = [],
  redirect = process.env.REACT_APP_MEDECIN_SIGN_IN_URL!,
}: ProtectedRouteProps) {
  const [user] = useUser();
  if (authStatus === AUTH_STATUSES.NOT_CHECKED) {
    return <ContentSpiner />;
  }

  if (
    authStatus === AUTH_STATUSES.LOGGED_OUT ||
    authStatus === AUTH_STATUSES.NOT_LOGGED
  ) {
    window.location.replace(generateAuthURL(redirect));
    return null;
  }

  if (roles.some((role) => !user?.groups?.includes(role))) {
    window.location.replace(redirect);
    return null;
  }

  return <Outlet />;
}

function AnonymousRoute({ authStatus, redirect = '/' }: RouteProps) {
  if (authStatus === AUTH_STATUSES.NOT_CHECKED) {
    return <ContentSpiner />;
  }

  if (authStatus === AUTH_STATUSES.LOGGED) {
    return <Navigate to={redirect} />;
  }

  return <Outlet />;
}

function StrictModeProvider() {
  return (
    <StrictMode>
      <Outlet />
    </StrictMode>
  );
}
