import { CloseCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import { Input, Form, DatePicker, Select, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Title from 'commons/components/layout/Title';
import { REQUEST_STATUSES } from 'commons/types/common';
import { MEETING_TYPES } from 'commons/types/meetingsTypes';
import { PatientStatus, PATIENT_STATUSES } from 'commons/types/patientsTypes';
import Card from 'components/layout/Card';
import MeetingList from 'components/meetings/MeetingList';
import Pagination from 'components/ui/pagination/Pagination';
import useDebounce from 'hooks/useDebounce';
import { useMeetingList } from 'stores/meetings/meetingsHooks';

const DATE_FORMAT = 'DD/MM/YYYY';

export default function Patients() {
  const [searchForm] = Form.useForm();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<PatientStatus | null>(null);
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs().startOf('day'),
    null,
  ]);
  const [sortOrder, setSortOrder] = useState<'ascend' | 'descend'>('ascend');
  const [currentPagePreviousPatients, setCurrentPagePreviousPatients] =
    useState(1);
  const [allMeetings, allMeetingsRequestStatus] = useMeetingList(
    new URLSearchParams({
      types: `${[MEETING_TYPES.RDV_3, MEETING_TYPES.RDV_PPC]}`,
      startDate: `${dateRange[0] ? dateRange[0].format() : ''}`,
      endDate: `${dateRange[1] ? dateRange[1].format() : ''}`,
      name: search,
      status: status || '',
      sortOrder,
      page: `${currentPagePreviousPatients}`,
      mode: 'extended',
    }),
  );

  const onClickMeeting = useCallback(
    (meetingID: string) => navigate(`/patients/${meetingID}`),
    [navigate],
  );
  const onChangeSearch = useDebounce((event: ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value),
  );
  const onClearSearch = useCallback(() => {
    searchForm.resetFields(['search']);
    setSearch('');
  }, [setSearch, searchForm]);
  const onChangeDateRange = useCallback(
    (newDateRange: null | [Dayjs | null, Dayjs | null]) => {
      if (newDateRange) {
        setDateRange(newDateRange);
      } else {
        setDateRange([null, null]);
      }
    },
    [setDateRange],
  );
  const onChangeStatus = useCallback(
    (newStatus: PatientStatus) => setStatus(newStatus),
    [],
  );
  const onClearStatus = useCallback(() => setStatus(null), []);
  const statusOptions = useMemo(
    () =>
      Object.values(PATIENT_STATUSES).map((value) => ({ value, label: value })),
    [],
  );
  const onChangeOrder = useCallback((newSortOrder: 'ascend' | 'descend') => {
    setSortOrder(newSortOrder);
  }, []);

  return (
    <div>
      <div className='flex flex-wrap space-x-4'>
        <Title main>Mes patients</Title>
        <div className='mb-4 w-[200px]'>
          <Form form={searchForm}>
            <Form.Item name='search'>
              <Input
                onChange={onChangeSearch}
                prefix={<SearchOutlined />}
                placeholder='Rechercher…'
                disabled={!!status}
                suffix={
                  search ? (
                    <CloseCircleTwoTone
                      twoToneColor='#94a3b8'
                      onClick={onClearSearch}
                      className='opacity-75 hover:opacity-100'
                    />
                  ) : null
                }
              />
            </Form.Item>
          </Form>
        </div>
        <div className='mb-4'>
          <DatePicker.RangePicker
            defaultValue={dateRange}
            onChange={onChangeDateRange}
            format={DATE_FORMAT}
          />
        </div>
        <div className='mb-4'>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: 'Montserrat',
                colorPrimary: '#fc6a38',
                colorTextBase: '#1E2248',
              },
              components: {
                Select: {
                  borderRadius: 6,
                },
              },
            }}
          >
            <Select
              placeholder='Filtrer par statut'
              onChange={onChangeStatus}
              onClear={onClearStatus}
              options={statusOptions}
              disabled={search !== ''}
              className='!rounded-full w-52'
              allowClear
            />
          </ConfigProvider>
        </div>
      </div>
      <Card>
        <MeetingList
          loading={allMeetingsRequestStatus === REQUEST_STATUSES.PENDING}
          onClickMeeting={onClickMeeting}
          meetings={allMeetings}
          onChangeOrder={onChangeOrder}
          sortOrder={sortOrder}
        />
        <div className='flex justify-end pt-4'>
          <Pagination
            loading={allMeetingsRequestStatus === REQUEST_STATUSES.PENDING}
            onChangePage={setCurrentPagePreviousPatients}
            currentPage={currentPagePreviousPatients}
            isOnLastPage={allMeetings?.length === 0}
          />
        </div>
      </Card>
    </div>
  );
}
