/* eslint-disable no-underscore-dangle */
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_ROOT_API_URL || 'http://localhost:8000';

const Request = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

Request.interceptors.response.use(
  (res) => res,
  async (error) => {
    const originalRequest = error.config;

    // Not authorized, try to refresh token
    if (
      (error.response.status === 403 || error.response.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      const [isFail, response] = await axios({
        method: 'POST',
        url: `${BASE_URL}/users/auth/refresh/`,
        withCredentials: true,
      })
        .then(async () => [false, await axios.request(originalRequest)])
        .catch(() => [true, null]);

      return isFail ? Promise.reject(isFail) : Promise.resolve(response);
    }
    return Promise.reject(error);
  },
);

export default Request;
