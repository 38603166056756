/* eslint-disable import/prefer-default-export */
import { useGlobalStoreData } from 'stores/hooks';
import {
  checkAuthAsync,
  getAuthRequestStatus,
  getAuthStatus,
} from './authSlice';

export function useAuthStatus() {
  return useGlobalStoreData({
    dataGetter: getAuthStatus,
    requestStatusGetter: getAuthRequestStatus,
    thunk: checkAuthAsync,
  });
}
