import { Button, Form, Input } from 'antd';
import { useCallback } from 'react';
import Card from 'components/layout/Card';
import {
  REQUIRED_FIELD_RULES,
  REQUIRED_PHONE_NUMBER_RULES,
} from 'libs/validation';
import type { User } from 'stores/user/userTypes';
import type { StepProps } from './AccountInformationsStepper';

export default function ContactStep({
  user,
  doctor,
  step = 0,
  onValidStep,
}: StepProps) {
  const onSubmit = useCallback(
    (values: User) =>
      onValidStep(step, {
        user: {
          ...user,
          ...values,
        },
        doctor,
      }),
    [onValidStep, step, user, doctor],
  );

  return (
    <Card>
      <Form
        initialValues={user}
        onFinish={onSubmit}
        layout='vertical'
      >
        <Form.Item
          className='font-semibold'
          label='Nom'
          name='lastName'
          rules={REQUIRED_FIELD_RULES}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='font-semibold'
          label='Prénom'
          name='firstName'
          rules={REQUIRED_FIELD_RULES}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='font-semibold'
          label='Nº de téléphone'
          name='phone'
          rules={REQUIRED_PHONE_NUMBER_RULES}
        >
          <Input />
        </Form.Item>
        <div className='flex justify-end pt-2'>
          <Button
            htmlType='submit'
            type='primary'
          >
            Suivant
          </Button>
        </div>
      </Form>
    </Card>
  );
}
