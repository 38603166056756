import cx from 'classnames';
import { ComponentProps, createElement, PropsWithChildren } from 'react';

interface TitleProps extends ComponentProps<'h1' | 'h2'> {
  main?: boolean;
}

export default function Title({
  children = null,
  className = '',
  main = false,
  ...otherProps
}: PropsWithChildren<TitleProps>) {
  return createElement(
    main ? 'h1' : 'h2',
    {
      className: cx(
        'font-bold mb-4',
        { 'text-3xl text-content': main },
        { 'text-2xl text-primary': !main },
        className,
      ),
      ...otherProps,
    },
    children,
  );
}
