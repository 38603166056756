import { useLocalStoreData, useCrudAction } from 'stores/hooks';
import {
  getCurrentDoctor,
  getCurrentDoctorRequestStatus,
  getUpdateDoctorRequestStatus,
  getDoctorAsync,
  updateDoctorAsync,
} from './doctorsSlice';

export function useCurrentDoctor() {
  return useLocalStoreData({
    dataGetter: getCurrentDoctor,
    requestStatusGetter: getCurrentDoctorRequestStatus,
    thunk: getDoctorAsync,
  });
}
export function useUpdateDoctor() {
  return useCrudAction({
    thunk: updateDoctorAsync,
    requestStatusGetter: getUpdateDoctorRequestStatus,
  });
}
