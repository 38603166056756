import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  DateSelectArg,
  EventClickArg,
  EventDropArg,
  DatesSetArg,
  EventContentArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, {
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Modal, Button, Spin } from 'antd';
import cx from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import {
  useCallback,
  useState,
  useMemo,
  useRef,
  Fragment,
  ReactNode,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Title from 'commons/components/layout/Title';
import { REQUEST_STATUSES } from 'commons/types/common';
import { MEETING_TYPES, Meeting } from 'commons/types/meetingsTypes';
import {
  WeekDay,
  Period,
  SpecialDay,
  DayOff,
} from 'commons/types/timeslotsTypes';
import {
  useCurrentTimeslots,
  useUpdateOrCreateTimeslot,
} from 'stores/agenda/timeslotsHooks';
import { useMeetingList } from 'stores/meetings/meetingsHooks';

enum EVENT_TYPES {
  SPECIAL_DAY = 'SPECIAL_DAY',
  DAY_OFF = 'DAY_OFF',
  WEEK_DAY = 'WEEK_DAY',
  MEETING = 'MEETING',
  PPC_MEETING = 'PPC_MEETING',
}

const EVENT_TYPES_CONFIG = {
  SPECIAL_DAY: {
    backgroundColor: '#1E2248',
    textColor: 'white',
    borderColor: '#1E2248',
  },
  DAY_OFF: {
    backgroundColor: '#888888',
    textColor: 'white',
    borderColor: '#888888',
  },
  WEEK_DAY: {
    backgroundColor: '#1E2248',
    textColor: 'white',
    borderColor: '#1E2248',
  },
  MEETING: {
    backgroundColor: '#FC6A38',
    textColor: 'white',
    borderColor: '#FC6A38',
  },
};

// Format the event date and time components as strings
const formatDateTime = (dateTime: Date) => {
  const dt = dayjs(dateTime);
  const formattedDate = dt.format('YYYY-MM-DD');
  const formattedTime = dt.format('HH:mm:ss');
  return { formattedDate, formattedTime };
};

const getDayIndexDate = (
  startDate: Dayjs,
  endDate: Dayjs,
  baseDayIndex: number,
) => {
  const dayIndex = baseDayIndex === 0 ? 7 : baseDayIndex;
  if (dayIndex < 1 || dayIndex > 7) {
    return startDate.format('YYYY-MM-DD');
  }
  let exactDate = startDate.add(dayIndex - 1, 'day');
  if (exactDate.isBefore(startDate) || exactDate.isAfter(endDate)) {
    exactDate = startDate;
  }
  return exactDate.format('YYYY-MM-DD');
};

interface CustomPrevButtonProps {
  onPrevClick: () => void;
}

function CustomPrevButton({ onPrevClick }: CustomPrevButtonProps) {
  return (
    <Button
      className='mr-1'
      type='default'
      shape='default'
      onClick={onPrevClick}
      icon={<LeftOutlined className='pb-8' />}
    />
  );
}

interface CustomTodayButtonProps {
  onTodayClick: () => void;
}

function CustomTodayButton({ onTodayClick }: CustomTodayButtonProps) {
  return (
    <Button
      className='ml-5 mr-1'
      type='default'
      shape='default'
      onClick={onTodayClick}
    >
      Aujourd&apos;hui
    </Button>
  );
}

interface CustomNextButtonProps {
  onNextClick: () => void;
}

function CustomNextButton({ onNextClick }: CustomNextButtonProps) {
  return (
    <Button
      className='mr-1'
      type='default'
      shape='default'
      onClick={onNextClick}
      icon={<RightOutlined className='pb-8' />}
    />
  );
}

interface AgendaComponentProps {
  admin?: boolean;
  doctorID?: string;
  title?: ReactNode;
}

export default function AgendaComponent({
  admin = false,
  doctorID = undefined,
  title = null,
}: AgendaComponentProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const calendarRef = useRef<FullCalendar | null>(null);
  const navigate = useNavigate();
  const [timeslots, requestStatus] = useCurrentTimeslots(doctorID);
  const [updateOrCreateTimeslot] = useUpdateOrCreateTimeslot();
  const [showWeekDayModal, setShowWeekDayModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedEventInfo, setSelectedEventInfo] = useState<any>(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>(() => {
    const searchDate = searchParams.get('date');
    const searchIsValid =
      searchDate && dayjs(searchDate, 'YYYY-MM-DD').isValid();
    const rangeDate = searchIsValid ? dayjs(searchDate, 'YYYY-MM-DD') : dayjs();
    const startOfWeek = rangeDate.startOf('week');
    const endOfWeek = rangeDate.endOf('week');
    return [startOfWeek, endOfWeek];
  });

  const [allMeetings, allMeetingsRequestStatus] = useMeetingList(
    new URLSearchParams({
      types: `${[MEETING_TYPES.RDV_3, MEETING_TYPES.RDV_PPC]}`,
      startDate: `${dateRange[0].format()}`,
      endDate: `${dateRange[1].format()}`,
    }),
  );

  const handleDatesSet = useCallback(
    (weekDates: DatesSetArg) => {
      const startDate = dayjs(weekDates.start);
      const endDate = dayjs(weekDates.end);

      setDateRange([startDate, endDate]);
      setSearchParams(
        { date: startDate.format('YYYY-MM-DD') },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  const meetings = useMemo(() => {
    if (allMeetingsRequestStatus !== REQUEST_STATUSES.SUCCESS) {
      return [];
    }
    return (
      allMeetings?.map((meeting: Meeting) => {
        const name = meeting.patient.member
          ? `${meeting.patient.member?.firstName} ${meeting.patient.member?.lastName}`
          : `${meeting.patient.metadata?.firstName} ${meeting.patient.metadata?.lastName}`;

        return {
          editable: false,
          title: name,
          start: dayjs(meeting.start_date).format(),
          end: dayjs(meeting.end_date).format(),
          backgroundColor: EVENT_TYPES_CONFIG.MEETING.backgroundColor,
          borderColor: EVENT_TYPES_CONFIG.MEETING.borderColor,
          textColor: EVENT_TYPES_CONFIG.MEETING.textColor,
          patient_id: meeting.patient.id,
          wp_type:
            meeting.type === MEETING_TYPES.RDV_3
              ? EVENT_TYPES.MEETING
              : EVENT_TYPES.PPC_MEETING,
        };
      }) || []
    );
  }, [allMeetings, allMeetingsRequestStatus]);

  const events = useMemo(
    () => [
      ...meetings,
      ...timeslots.weekDayList
        .map((wd: WeekDay) =>
          wd.periodList.map((p: Period) => ({
            title: 'Dispo. récurrente',
            start: `${getDayIndexDate(
              dateRange[0],
              dateRange[1],
              wd.dayIndex,
            )}T${p.startTime}`,
            end: `${getDayIndexDate(dateRange[0], dateRange[1], wd.dayIndex)}T${
              p.endTime
            }`,
            display: 'background',
            backgroundColor: EVENT_TYPES_CONFIG.WEEK_DAY.backgroundColor,
            borderColor: EVENT_TYPES_CONFIG.WEEK_DAY.borderColor,
            textColor: EVENT_TYPES_CONFIG.WEEK_DAY.textColor,
            wp_id: p.id,
            wp_type: EVENT_TYPES.WEEK_DAY,
          })),
        )
        .flat(),
      ...timeslots.specialDayList
        .map((sd: SpecialDay) =>
          sd.periodList.map((p: Period) => ({
            title: 'Dispo. exceptionnelle',
            start: `${sd.startDate}T${p.startTime}`,
            end: `${sd.endDate}T${p.endTime}`,
            backgroundColor: EVENT_TYPES_CONFIG.SPECIAL_DAY.backgroundColor,
            borderColor: EVENT_TYPES_CONFIG.SPECIAL_DAY.borderColor,
            textColor: EVENT_TYPES_CONFIG.SPECIAL_DAY.textColor,
            wp_id: sd.id,
            wp_type: EVENT_TYPES.SPECIAL_DAY,
          })),
        )
        .flat(),
      ...timeslots.dayOffList.map((doff: DayOff) => ({
        title: 'Indisponibilité',
        start: doff.startDate,
        end: dayjs(doff.endDate).add(1, 'day').format('YYYY-MM-DD'),
        allDay: true,
        backgroundColor: EVENT_TYPES_CONFIG.DAY_OFF.backgroundColor,
        borderColor: EVENT_TYPES_CONFIG.DAY_OFF.borderColor,
        textColor: EVENT_TYPES_CONFIG.DAY_OFF.textColor,
        wp_id: doff.id,
        wp_type: EVENT_TYPES.DAY_OFF,
      })),
    ],
    [timeslots, meetings, dateRange],
  );

  const isEventDayOff = useCallback(
    (info: EventContentArg) => {
      if (info.event.extendedProps.wp_type === EVENT_TYPES.DAY_OFF) {
        return false;
      }
      const eventDate = info.event.start;
      const date = dayjs(eventDate);
      const formattedDate = date.format('YYYY-MM-DD');
      return timeslots.dayOffList.some(
        (doff: DayOff) =>
          formattedDate >= doff.startDate && formattedDate <= doff.endDate,
      );
    },
    [timeslots],
  );
  const isEventPPC = useCallback(
    (info: EventContentArg) =>
      info.event.extendedProps.wp_type === EVENT_TYPES.PPC_MEETING,
    [],
  );

  const handleEventClick = useCallback(
    (info: EventClickArg) => {
      if (
        info.event.extendedProps.wp_type === EVENT_TYPES.MEETING ||
        info.event.extendedProps.wp_type === EVENT_TYPES.PPC_MEETING
      ) {
        navigate(`/patients/${info.event.extendedProps.patient_id}`);
      } else {
        setSelectedEventInfo(info);
        setShowDeleteModal(true);
      }
    },
    [navigate],
  );

  const deleteEvent = useCallback(() => {
    const eventId = selectedEventInfo.event.extendedProps.wp_id;
    // Remove the event with the specified wp_id from the timeslots
    const updatedTimeslots = {
      ...timeslots,
      weekDayList: timeslots.weekDayList,
      specialDayList: timeslots.specialDayList.filter(
        (sd: SpecialDay) => sd.id !== eventId,
      ),
      dayOffList: timeslots.dayOffList.filter(
        (doff: DayOff) => doff.id !== eventId,
      ),
    };
    updateOrCreateTimeslot(updatedTimeslots);
    setShowDeleteModal(false);
  }, [selectedEventInfo, timeslots, updateOrCreateTimeslot]);

  const handleEventDrop = useCallback(
    (info: EventDropArg) => {
      const eventType = info.event.extendedProps.wp_type;
      const eventId = info.event.extendedProps.wp_id;
      const newStart = info.event.start;
      const newEnd = info.event.end;
      if (!newStart || !newEnd) {
        return;
      }
      const { formattedDate: startDate, formattedTime: startTime } =
        formatDateTime(newStart);
      const { formattedDate: endDate, formattedTime: endTime } =
        formatDateTime(newEnd);

      switch (eventType) {
        case EVENT_TYPES.SPECIAL_DAY: {
          const updatedTimeslots = {
            ...timeslots,
            specialDayList: timeslots.specialDayList.map((sd: SpecialDay) => {
              if (sd.id === eventId) {
                return {
                  ...sd,
                  startDate,
                  endDate,
                  periodList: sd.periodList.map((p: Period) => ({
                    ...p,
                    startTime,
                    endTime,
                  })),
                };
              }
              return sd;
            }),
          };
          updateOrCreateTimeslot(updatedTimeslots);
          break;
        }
        case EVENT_TYPES.DAY_OFF: {
          const updatedTimeslots = {
            ...timeslots,
            dayOffList: timeslots.dayOffList.map((doff: DayOff) => {
              if (doff.id === eventId) {
                return {
                  ...doff,
                  startDate,
                  endDate: dayjs(newEnd)
                    .subtract(1, 'day')
                    .format('YYYY-MM-DD'),
                };
              }
              return doff;
            }),
          };
          updateOrCreateTimeslot(updatedTimeslots);
          break;
        }
        default: {
          break;
        }
      }
    },
    [timeslots, updateOrCreateTimeslot],
  );

  const handleEventResize = useCallback(
    (info: EventResizeDoneArg) => {
      const eventId = info.event.extendedProps.wp_id;
      const eventType = info.event.extendedProps.wp_type;
      const newStart = info.event.start;
      const newEnd = info.event.end;
      if (!newStart || !newEnd) {
        return;
      }
      const { formattedDate: startDate, formattedTime: startTime } =
        formatDateTime(newStart);
      const { formattedDate: endDate, formattedTime: endTime } =
        formatDateTime(newEnd);

      switch (eventType) {
        case EVENT_TYPES.SPECIAL_DAY: {
          const updatedTimeslots = {
            ...timeslots,
            specialDayList: timeslots.specialDayList.map((sd: SpecialDay) => {
              if (sd.id === eventId) {
                return {
                  ...sd,
                  startDate,
                  endDate,
                  periodList: sd.periodList.map((p: Period) => ({
                    ...p,
                    startTime,
                    endTime,
                  })),
                };
              }
              return sd;
            }),
          };
          updateOrCreateTimeslot(updatedTimeslots);
          break;
        }
        case EVENT_TYPES.DAY_OFF: {
          const updatedTimeslots = {
            ...timeslots,
            dayOffList: timeslots.dayOffList.map((doff: DayOff) => {
              if (doff.id === eventId) {
                return {
                  ...doff,
                  startDate,
                  endDate: dayjs(newEnd)
                    .subtract(1, 'day')
                    .format('YYYY-MM-DD'),
                };
              }
              return doff;
            }),
          };
          updateOrCreateTimeslot(updatedTimeslots);
          break;
        }
        default: {
          break;
        }
      }
    },
    [timeslots, updateOrCreateTimeslot],
  );

  const createEvent = useCallback(
    (type: EVENT_TYPES) => {
      const newStart = selectedEventInfo.start;
      const newEnd = selectedEventInfo.end;
      if (!newStart || !newEnd) {
        return;
      }
      const { formattedDate: startDate, formattedTime: startTime } =
        formatDateTime(newStart);
      const { formattedDate: endDate, formattedTime: endTime } =
        formatDateTime(newEnd);
      switch (type) {
        case EVENT_TYPES.SPECIAL_DAY: {
          const newPeriod: Period = {
            startTime,
            endTime,
          };
          const newSpecial: SpecialDay = {
            startDate,
            endDate,
            periodList: [newPeriod],
          };

          // Update the event's start and end times
          const updatedTimeslots = {
            ...timeslots,
            specialDayList: [...timeslots.specialDayList, newSpecial],
          };
          updateOrCreateTimeslot(updatedTimeslots);
          break;
        }
        case EVENT_TYPES.WEEK_DAY: {
          const dayIndex = selectedEventInfo.start.getDay();
          const newPeriod: Period = {
            startTime,
            endTime,
            periodServiceList: [],
            locationId: '',
          };
          const matchingWeekDays = timeslots.weekDayList.filter(
            (wd: WeekDay) => wd.dayIndex === dayIndex,
          );
          if (matchingWeekDays.length) {
            const currentWeekDay = matchingWeekDays[0];
            const newWeekDay: WeekDay = {
              ...currentWeekDay,
              periodList: [...[newPeriod], ...currentWeekDay.periodList],
            };
            const updatedTimeslots = {
              ...timeslots,
              weekDayList: [
                ...timeslots.weekDayList.filter(
                  (wd: WeekDay) => wd.dayIndex !== dayIndex,
                ),
                newWeekDay,
              ],
            };
            updateOrCreateTimeslot(updatedTimeslots);
          } else {
            const newWeekDay: WeekDay = {
              dayIndex,
              endTime,
              startTime,
              timeOutList: [],
              periodList: [newPeriod],
            };
            const updatedTimeslots = {
              ...timeslots,
              weekDayList: [...timeslots.weekDayList, newWeekDay],
            };
            updateOrCreateTimeslot(updatedTimeslots);
          }
          break;
        }
        case EVENT_TYPES.DAY_OFF: {
          const newDayOff: DayOff = {
            startDate,
            endDate: dayjs(newEnd).subtract(1, 'day').format('YYYY-MM-DD'),
            repeat: false,
            name: 'Indiponibilité',
          };
          const updatedTimeslots = {
            ...timeslots,
            dayOffList: [...timeslots.dayOffList, newDayOff],
          };
          updateOrCreateTimeslot(updatedTimeslots);
          break;
        }
        default: {
          break;
        }
      }
      setShowCreateModal(false);
    },
    [selectedEventInfo, timeslots, updateOrCreateTimeslot],
  );

  const createDayOffEvent = useCallback(
    (info: DateSelectArg) => {
      const newStart = info.start;
      const newEnd = info.end;
      if (!newStart || !newEnd) {
        return;
      }
      const { formattedDate: startDate } = formatDateTime(newStart);
      const newDayOff: DayOff = {
        startDate,
        endDate: dayjs(newEnd).subtract(1, 'day').format('YYYY-MM-DD'),
        repeat: false,
        name: 'Indiponibilité',
      };
      const updatedTimeslots = {
        ...timeslots,
        dayOffList: [...timeslots.dayOffList, newDayOff],
      };
      updateOrCreateTimeslot(updatedTimeslots);
    },
    [timeslots, updateOrCreateTimeslot],
  );

  const onCancelNewEventModal = useCallback(
    () => setShowCreateModal(false),
    [],
  );
  const onCancelDeleteEventModal = useCallback(
    () => setShowDeleteModal(false),
    [],
  );
  const onCancelWeekDayModal = useCallback(() => {
    setShowWeekDayModal(false);
    setShowCreateModal(false);
  }, []);

  const handleSelect = useCallback(
    (info: DateSelectArg) => {
      setSelectedEventInfo(info);
      if (!info.allDay) {
        setShowCreateModal(true);
      } else {
        createDayOffEvent(info);
      }
    },
    [createDayOffEvent],
  );

  const handleSpecialDayButtonClick = useCallback(() => {
    createEvent(EVENT_TYPES.SPECIAL_DAY);
  }, [createEvent]);

  const handleWeekDayButtonClick = useCallback(() => {
    setShowWeekDayModal(true);
  }, []);

  const createWeekDayEvent = useCallback(() => {
    createEvent(EVENT_TYPES.WEEK_DAY);
    setShowWeekDayModal(false);
  }, [createEvent]);

  const isLoading = useCallback(
    () =>
      !timeslots ||
      requestStatus === REQUEST_STATUSES.PENDING ||
      allMeetingsRequestStatus === REQUEST_STATUSES.PENDING,
    [timeslots, requestStatus, allMeetingsRequestStatus],
  );

  const handlePrevClick = useCallback(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().prev();
    }
  }, []);

  const handleNextClick = useCallback(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().next();
    }
  }, []);

  const handleTodayClick = useCallback(() => {
    if (calendarRef.current) {
      calendarRef.current.getApi().today();
    }
  }, []);
  const eventClassNames = useCallback(
    (info: EventContentArg) => {
      const opacity = isEventDayOff(info) ? '!opacity-40' : '!opacity-100';
      const bgImage = isEventPPC(info) ? 'bg-stripes' : '';

      return [opacity, bgImage]
        .filter((className) => className !== '')
        .join(' ');
    },
    [isEventDayOff, isEventPPC],
  );

  return (
    <Fragment>
      <Modal
        title='Nouvel évènement'
        open={showCreateModal}
        onCancel={onCancelNewEventModal}
        footer={null}
      >
        <p>Quel type d&apos;évènement souhaitez-vous créer?</p>
        <div className='grid grid-cols-1 gap-4'>
          <Button
            onClick={handleSpecialDayButtonClick}
            type='default'
            style={{
              marginTop: 16,
              backgroundColor: EVENT_TYPES_CONFIG.SPECIAL_DAY.borderColor,
              color: EVENT_TYPES_CONFIG.SPECIAL_DAY.textColor,
            }}
          >
            Dispo. exceptionnelle
          </Button>
          <Button
            className='!bg-background-z-10'
            onClick={handleWeekDayButtonClick}
          >
            Dispo. récurrente
          </Button>
        </div>
      </Modal>
      <Modal
        title='Suppression'
        open={showDeleteModal}
        onOk={deleteEvent}
        onCancel={onCancelDeleteEventModal}
        okText='Supprimer'
        cancelText='Annuler'
      >
        <p>
          Êtes-vous sûr de vouloir supprimer cet évènement?
          <br />
          Les RDV déjà planifiés ne sont plus annulables. En cas de problème,
          contactez directement Hapni.
        </p>
      </Modal>
      <Modal
        title='Nouvelle disponibilité récurrente'
        open={showWeekDayModal}
        okType='danger'
        onOk={createWeekDayEvent}
        onCancel={onCancelWeekDayModal}
        okText='Confirmer'
        cancelText='Annuler'
      >
        <p>
          Vous êtes sur le point de créer une nouvelle disponibilité récurrente.
        </p>
        <p className='mt-3 font-bold'>
          {`Heure de début : ${
            selectedEventInfo
              ? formatDateTime(selectedEventInfo.start).formattedTime
              : ''
          }`}
        </p>
        <p className='font-bold'>
          {`Heure de fin : ${
            selectedEventInfo
              ? formatDateTime(selectedEventInfo.end).formattedTime
              : null
          }`}
        </p>
        <p className='mt-3'>
          Celle-ci ne pourra être ni modifiée, ni supprimée depuis cette
          interface. Si vous souhaitez y apporter des modifications, contactez
          le service Hapni.
        </p>
      </Modal>
      <div className='text-sm'>
        <div className='flex justify-between mb-4'>
          {!title && (
            <Title
              main
              className='!mb-0'
            >
              Mes disponibilités
            </Title>
          )}
          {title}
          <div className='flex items-center'>
            {isLoading() && <Spin />}
            <CustomTodayButton onTodayClick={handleTodayClick} />
            <CustomPrevButton onPrevClick={handlePrevClick} />
            <CustomNextButton onNextClick={handleNextClick} />
          </div>
        </div>
        <div className={cx({ 'pointer-events-none': admin })}>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialDate={dateRange[0].toDate()}
            initialView='timeGridWeek'
            slotDuration='00:30:00'
            firstDay={1}
            expandRows
            headerToolbar={false}
            ref={calendarRef}
            buttonText={{
              today: "Aujourd'hui",
            }}
            loading={isLoading}
            events={events}
            slotMinTime='08:00:00'
            slotMaxTime='21:00:00'
            displayEventTime={false}
            slotLabelFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            }}
            height={720}
            locale='fr'
            allDayText='All day'
            nowIndicator
            editable
            selectable
            datesSet={handleDatesSet}
            select={handleSelect}
            eventClick={handleEventClick}
            eventDrop={handleEventDrop}
            eventResize={handleEventResize}
            eventClassNames={eventClassNames}
          />
        </div>
      </div>
    </Fragment>
  );
}
