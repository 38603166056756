import {
  Row,
  Col,
  Tabs,
  Form,
  Radio,
  Input,
  Checkbox,
  DatePicker,
  Typography,
  FormInstance,
} from 'antd';
import type { TabsProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Fragment, useState, FormEvent, useCallback } from 'react';

const { Title } = Typography;

interface PPCTreatmentProps {
  required: boolean;
}

function PPCTreatment({ required }: PPCTreatmentProps) {
  const preventEnterDefaultBehavior = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => event.preventDefault(),
    [],
  );

  return (
    <Fragment>
      <Row gutter={8}>
        <Title level={5}>Type de prescription:</Title>
      </Row>
      <Row gutter={8}>
        <Form.Item name={['treatment', 'ppcInitialPrescription']}>
          <Radio.Group name='ppcInitialPrescription'>
            <Radio value='init'>Prescription initiale</Radio>
            <Radio value='month'>1er renouvellement à 4 mois</Radio>
            <Radio value='year'>Renouvellement annuel</Radio>
          </Radio.Group>
        </Form.Item>
      </Row>

      <Row gutter={8}>
        <span className='ant-form-text'>Type d’appareil de PPC:</span>
      </Row>
      <Row gutter={8}>
        <Form.Item name={['treatment', 'ppcChoice']}>
          <Radio.Group name='ppcChoice'>
            <Radio value='auto'>autopilotée</Radio>
            <Radio value='fixe'>à pression fixe</Radio>
            <Radio value='double'>à double niveau de pression</Radio>
          </Radio.Group>
        </Form.Item>
      </Row>

      <Row gutter={8}>
        <Form.Item
          name={['treatment', 'ppcPression']}
          label='Pression ou intervalle de pression (cm H2O) '
          rules={[{ required }]}
        >
          <Input.TextArea
            name='ppcPression'
            rows={3}
            cols={63}
            onPressEnter={preventEnterDefaultBehavior}
            maxLength={56}
          />
        </Form.Item>
      </Row>

      <Row gutter={8}>
        <Form.Item
          name={['treatment', 'ppcSettings']}
          label='Réglage particulier'
          rules={[{ required }]}
        >
          <Input.TextArea
            name='ppcSettings'
            rows={3}
            cols={63}
            onPressEnter={preventEnterDefaultBehavior}
            maxLength={78}
          />
        </Form.Item>
      </Row>

      <Row gutter={8}>
        <Form.Item
          name={['treatment', 'ppcInterface']}
          label='Type d’interface'
          rules={[{ required }]}
        >
          <Input.TextArea
            name='ppcInterface'
            rows={4}
            cols={63}
            onPressEnter={preventEnterDefaultBehavior}
            maxLength={300}
          />
        </Form.Item>
      </Row>

      <Row gutter={8}>
        <Form.Item
          name={['treatment', 'ppcBrand']}
          label='Marque et modèle'
        >
          <Input.TextArea
            name='ppcBrand'
            rows={3}
            cols={63}
            onPressEnter={preventEnterDefaultBehavior}
            maxLength={78}
          />
        </Form.Item>
      </Row>
    </Fragment>
  );
}

interface OAMTreatmentProps {
  form: FormInstance;
}
function OAMTreatment({ form }: OAMTreatmentProps) {
  const [formValue, setFormValue] = useState(
    form.getFieldValue(['treatment', 'oamDentalExamination']),
  );
  const changeCheckBox = useCallback((e: CheckboxChangeEvent) => {
    setFormValue(e.target.checked);
  }, []);
  const preventEnterDefaultBehavior = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => event.preventDefault(),
    [],
  );

  return (
    <Fragment>
      <Row gutter={8}>
        <Title level={5}>Type de prescription</Title>
      </Row>
      <Row gutter={8}>
        <Form.Item name={['treatment', 'oamInitialPrescription']}>
          <Radio.Group name='oamInitialPrescription'>
            <Radio value='init'>Prescription initiale</Radio>
            <Radio value='renew'>Renouvellement</Radio>
          </Radio.Group>
        </Form.Item>
      </Row>
      <Row gutter={8}>
        <Col>
          <Form.Item
            valuePropName='checked'
            name={['treatment', 'oamDentalExamination']}
          >
            <Checkbox onChange={changeCheckBox}>
              Examen dentaire réalisé
            </Checkbox>
          </Form.Item>
        </Col>
        {formValue && (
          <Col>
            <Form.Item
              name={['treatment', 'oamDentalExaminationDate']}
              rules={[{ required: formValue }]}
            >
              <DatePicker
                format='DD/MM/YYYY'
                name='oamDentalExaminationDate'
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={8}>
        <Form.Item
          name={['treatment', 'oamModel']}
          label='Marque et modèle'
        >
          <Input.TextArea
            name='oamModel'
            rows={3}
            cols={63}
            onPressEnter={preventEnterDefaultBehavior}
            maxLength={73}
          />
        </Form.Item>
      </Row>
    </Fragment>
  );
}

const TREATMENT_TYPES = {
  PPC: 'PPC',
  OAM: 'OAM',
};
interface CERFATreatmentProps {
  form: FormInstance;
}
export default function CERFATreatment({ form }: CERFATreatmentProps) {
  const treatmentType = Form.useWatch(['treatment', 'type'], form);
  const onChangeTab = useCallback(
    (value: string) => {
      form.resetFields(['treatment', 'prescription']);
      form.setFieldValue(['treatment', 'type'], value);
    },
    [form],
  );

  const items: TabsProps['items'] = [
    {
      key: TREATMENT_TYPES.PPC,
      label: 'Traitement par pression positive continue (PPC)',
      children: (
        <PPCTreatment required={treatmentType === TREATMENT_TYPES.PPC} />
      ),
    },
    {
      key: TREATMENT_TYPES.OAM,
      label: 'Traitement par orthèse d’avancée mandibulaire (OAM)',
      children: <OAMTreatment form={form} />,
    },
  ];

  return (
    <Fragment>
      <Form.Item
        name={['treatment', 'type']}
        style={{ display: 'none' }}
      >
        <Input name='type' />
      </Form.Item>
      <Tabs
        items={items}
        onChange={onChangeTab}
      />
    </Fragment>
  );
}
