/* eslint-disable import/prefer-default-export */
import Request from 'commons/libs/requests';

let getMeetingsController: AbortController;

export async function getMeetings(
  params: URLSearchParams,
  cancelPrevious: boolean = true,
) {
  if (cancelPrevious) {
    if (getMeetingsController?.abort) {
      getMeetingsController.abort();
    }

    getMeetingsController = new AbortController();
  }

  const response = await Request.get('/api/meetings/', {
    params,
    signal: getMeetingsController.signal,
  });
  return response.data;
}
