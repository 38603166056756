import { EditOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Title from 'commons/components/layout/Title';
import { REQUEST_STATUSES } from 'commons/types/common';
import { signoutAsync } from 'stores/auth/authSlice';
import { useCurrentDoctor } from 'stores/doctors/doctorsHooks';
import { getUpdateDoctorRequestStatus } from 'stores/doctors/doctorsSlice';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import { useUser } from 'stores/user/userHooks';
import AccountInformationsStepper from './new-account/AccountInformationsStepper';

export default function NewAccount() {
  const dispatch = useAppDispatch();
  const patchUserRequestStatus = useAppSelector(getUpdateDoctorRequestStatus);
  const [editMode, setEditMode] = useState(false);
  const [user] = useUser();
  const [doctor] = useCurrentDoctor();

  const onClickEdit = useCallback(() => setEditMode(true), []);
  const onClickSignOut = useCallback(
    () => dispatch(signoutAsync()),
    [dispatch],
  );

  useEffect(() => {
    if (doctor && !doctor.completed) {
      setEditMode(true);
    }
  }, [doctor]);

  useEffect(() => {
    if (patchUserRequestStatus === REQUEST_STATUSES.SUCCESS) {
      setEditMode(false);
    }
  }, [patchUserRequestStatus]);

  if (!user || !doctor) {
    return (
      <div className='flex flex-col items-center justify-center grow'>
        <Spin />
      </div>
    );
  }

  if (doctor.activated) {
    return (
      <Navigate
        to='/'
        replace
      />
    );
  }

  return (
    <div>
      <div className='h-24 mt-6'>
        {!doctor?.completed && (
          <Title className='text-center'>Finalisez votre inscription</Title>
        )}
        {doctor?.completed && (
          <Title className='text-center'>
            Votre compte est en attente de validation par les équipes
            d&apos;Hapni
          </Title>
        )}
        {patchUserRequestStatus === REQUEST_STATUSES.SUCCESS && (
          <Title
            main
            className='text-lg text-center'
          >
            Vos informations ont bien été enregistrées
          </Title>
        )}
      </div>
      {editMode && (
        <AccountInformationsStepper
          user={user}
          doctor={doctor}
        />
      )}
      {!editMode && (
        <div className='mt-12 text-center'>
          <Button
            size='large'
            onClick={onClickEdit}
            className='!inline-flex !items-center mb-12'
          >
            <EditOutlined />
            Editer mes informations
          </Button>
        </div>
      )}
      <div className='text-center'>
        <Button
          onClick={onClickSignOut}
          type='link'
          danger
        >
          Se déconnecter
        </Button>
      </div>
    </div>
  );
}
