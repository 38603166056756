import { useEffect } from 'react';

export default function useTailwindStyleTagOrder() {
  useEffect(() => {
    const head = document.querySelector('head');
    if (!head) {
      return;
    }

    let tailWindStyleTag = Array.from(head.getElementsByTagName('style')).find(
      (style) => style.innerHTML.includes('tailwind'),
    );

    if (!tailWindStyleTag) {
      tailWindStyleTag = Array.from(head.getElementsByTagName('link')).find(
        (link) => link.href.includes('css/main'),
      );
    }

    if (tailWindStyleTag) {
      head.insertAdjacentElement('afterbegin', tailWindStyleTag);
    }
  }, []);
}
