import { useEffect } from 'react';
import AgendaComponent from 'components/agenda/AgendaComponent';
import { useAppDispatch } from 'stores/hooks';
import { resetPatientLists } from 'stores/patients/patientsSlice';

export default function Agenda() {
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(resetPatientLists());
    },
    [dispatch],
  );

  return <AgendaComponent />;
}
