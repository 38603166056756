import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus, REQUEST_STATUSES } from 'commons/types/common';
import { Doctor, HubspotToken } from 'commons/types/doctorsTypes';
import type { RootState } from 'stores/store';
import type { User } from 'stores/user/userTypes';
import { getDoctor, patchDoctor, getHubspotDoctor } from './doctorsClient';

export interface DoctorsState {
  current?: Doctor;
  hubspotToken?: HubspotToken;
  currentRequestStatus?: RequestStatus;
  hubspotRequestStatus?: RequestStatus;
  patchDoctorRequestStatus?: RequestStatus;
}

const initialState: DoctorsState = {};

export const getDoctorAsync = createAsyncThunk('doctors/getCurrent', () =>
  getDoctor(),
);
export const getDoctorHubspotAsync = createAsyncThunk(
  'user/getHubspot',
  ({
    email,
    firstName,
    lastName,
  }: {
    email: string;
    firstName: string;
    lastName: string;
  }) => getHubspotDoctor(email, firstName, lastName),
);

export interface IUpdateDoctorAsync {
  id: string;
  user: User;
  doctor: Doctor;
  ribFile?: File | null;
  signatureFile?: File | null;
}
export const updateDoctorAsync = createAsyncThunk(
  'doctors/updateCurrent',
  (params: IUpdateDoctorAsync) => patchDoctor(params),
);

export const doctorsSlice = createSlice({
  name: 'doctors',
  initialState,
  reducers: {
    resetCurrentDoctor: (state) => {
      delete state.current;
      delete state.hubspotToken;
      delete state.currentRequestStatus;
      delete state.patchDoctorRequestStatus;
      delete state.hubspotRequestStatus;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDoctorAsync.pending, (state) => {
        state.currentRequestStatus = REQUEST_STATUSES.PENDING;
      })
      .addCase(getDoctorAsync.fulfilled, (state, { payload }) => {
        state.currentRequestStatus = REQUEST_STATUSES.SUCCESS;
        state.current = payload;
      })
      .addCase(getDoctorAsync.rejected, (state) => {
        state.currentRequestStatus = REQUEST_STATUSES.ERROR;
      })
      .addCase(updateDoctorAsync.pending, (state) => {
        state.patchDoctorRequestStatus = REQUEST_STATUSES.PENDING;
      })
      .addCase(updateDoctorAsync.fulfilled, (state, { payload }) => {
        state.patchDoctorRequestStatus = REQUEST_STATUSES.SUCCESS;
        state.current = payload;
      })
      .addCase(updateDoctorAsync.rejected, (state) => {
        state.patchDoctorRequestStatus = REQUEST_STATUSES.ERROR;
      })
      .addCase(getDoctorHubspotAsync.pending, (state) => {
        state.hubspotRequestStatus = REQUEST_STATUSES.PENDING;
      })
      .addCase(getDoctorHubspotAsync.fulfilled, (state, { payload }) => {
        state.hubspotRequestStatus = REQUEST_STATUSES.SUCCESS;
        state.hubspotToken = payload;
      })
      .addCase(getDoctorHubspotAsync.rejected, (state) => {
        state.hubspotRequestStatus = REQUEST_STATUSES.ERROR;
      });
  },
});

export const { resetCurrentDoctor } = doctorsSlice.actions;

export const getCurrentDoctor = (state: RootState) => state.doctors.current;
export const getHubspotToken = (state: RootState) => state.doctors.hubspotToken;
export const getCurrentDoctorRequestStatus = (state: RootState) =>
  state.doctors.currentRequestStatus;
export const getUpdateDoctorRequestStatus = (state: RootState) =>
  state.doctors.patchDoctorRequestStatus;
export const getHubspotRequestStatus = (state: RootState) =>
  state.doctors.hubspotRequestStatus;

export default doctorsSlice.reducer;
