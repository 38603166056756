import { Button, Form, Input } from 'antd';
import { useCallback } from 'react';
import Card from 'components/layout/Card';
import {
  REQUIRED_FIELD_RULES,
  REQUIRED_RPPS_NUMBER_RULES,
  REQUIRED_STRUCT_NUMBER_RULES,
} from 'libs/validation';
import type { User } from 'stores/user/userTypes';
import type { StepProps } from './AccountInformationsStepper';

interface AdministrativeProps extends StepProps {
  loading?: boolean;
}

export default function AdministrativeStep({
  user,
  doctor,
  step = 4,
  onValidStep,
  loading = false,
  changeStepToPrevious = () => {},
}: AdministrativeProps) {
  const onSubmit = useCallback(
    (values: User) =>
      onValidStep(step, {
        user,
        doctor: {
          ...doctor,
          ...values,
        },
      }),
    [onValidStep, step, user, doctor],
  );

  return (
    <Card>
      <Form
        initialValues={doctor}
        onFinish={onSubmit}
        layout='vertical'
      >
        <Form.Item
          className='font-semibold'
          label='Spécialité'
          name='speciality'
          rules={REQUIRED_FIELD_RULES}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='font-semibold'
          label='Nº RPPS'
          name='rpps'
          rules={REQUIRED_RPPS_NUMBER_RULES}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='font-semibold'
          label='Raison sociale'
          name='r_social'
          rules={REQUIRED_FIELD_RULES}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='font-semibold'
          label='Nº de structure (AM, FINESS ou SIRET)'
          name='n_struct'
          rules={REQUIRED_STRUCT_NUMBER_RULES}
        >
          <Input />
        </Form.Item>
        <div className='flex justify-between pt-2'>
          <Button onClick={changeStepToPrevious}>Précédent</Button>
          <Button
            htmlType='submit'
            disabled={loading}
            loading={loading}
            type='primary'
          >
            Valider
          </Button>
        </div>
      </Form>
    </Card>
  );
}
