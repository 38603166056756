import { Button, Form, Input, Radio } from 'antd';
import { useCallback } from 'react';
import {
  MEETING_DURATION_TIME,
  MEETING_DURATION,
} from 'commons/types/meetingsTypes';
import Card from 'components/layout/Card';
import { REQUIRED_FIELD_RULES } from 'libs/validation';
import type { User } from 'stores/user/userTypes';
import type { StepProps } from './AccountInformationsStepper';

const MEETING_DURATION_OPTIONS = [
  { label: MEETING_DURATION_TIME.LONG, value: MEETING_DURATION.LONG },
  { label: MEETING_DURATION_TIME.SHORT, value: MEETING_DURATION.SHORT },
];

export default function BillingStep({
  user,
  doctor,
  step = 1,
  onValidStep,
  changeStepToPrevious = () => {},
}: StepProps) {
  const onSubmit = useCallback(
    (values: User) =>
      onValidStep(step, {
        user,
        doctor: {
          ...doctor,
          ...values,
        },
      }),
    [onValidStep, step, user, doctor],
  );

  return (
    <Card>
      <Form
        initialValues={{
          ...doctor,
          meeting_duration: doctor.meeting_duration || MEETING_DURATION.LONG,
        }}
        onFinish={onSubmit}
        layout='vertical'
      >
        <Form.Item
          className='font-semibold'
          label='Adresse'
          name='address'
          rules={REQUIRED_FIELD_RULES}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='font-semibold'
          label='Code postal'
          name='zip'
          rules={REQUIRED_FIELD_RULES}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='font-semibold'
          label='Ville'
          name='city'
          rules={REQUIRED_FIELD_RULES}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className='font-semibold'
          label='Durée des rendez-vous patient'
          name='meeting_duration'
        >
          <Radio.Group
            options={MEETING_DURATION_OPTIONS}
            optionType='button'
            buttonStyle='solid'
          />
        </Form.Item>
        <div className='flex justify-between pt-2'>
          <Button onClick={changeStepToPrevious}>Précédent</Button>
          <Button
            htmlType='submit'
            type='primary'
          >
            Suivant
          </Button>
        </div>
      </Form>
    </Card>
  );
}
