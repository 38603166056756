import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RcFile } from 'antd/lib/upload/interface';
import dayjs, { Dayjs } from 'dayjs';
import { RequestStatus, REQUEST_STATUSES } from 'commons/types/common';
import { Doctor } from 'commons/types/doctorsTypes';
import { DocumentType, Document } from 'commons/types/documentsTypes';
import {
  Patient,
  PatientStatus,
  PatientNonVenuOption,
} from 'commons/types/patientsTypes';
import type { RootState } from 'stores/store';
import { User } from 'stores/user/userTypes';
import {
  generateCerfa,
  getPatient,
  getPatients,
  patchPatient,
  fetchPatientDocuments,
  fetchPatientDocument,
  createDocument,
} from './patientsClient';
import type {
  Insured,
  Supported,
  Treatment,
  Prescription,
} from './patientsTypes';

export interface PatientsState {
  current?: Patient;
  documents?: Document[];
  currentRequestStatus?: RequestStatus;
  documentsRequestStatus?: RequestStatus;
  createDocumentRequestStatus?: RequestStatus;
  fetchDocumentRequestStatus?: RequestStatus;
  updateCurrentRequestStatus?: RequestStatus;
  list: Patient[];
  listRequestStatus?: RequestStatus;
  generatedCERFAContent?: string;
  generatedCERFARequestStatus?: RequestStatus;
}

const initialState: PatientsState = {
  list: [],
};

export const getPatientAsync = createAsyncThunk(
  'patients/current',
  (patientID: string) => getPatient(patientID),
);
export const getPatientDocumentsAsync = createAsyncThunk(
  'patients/documents',
  (patientID: string) => fetchPatientDocuments(patientID),
);
export const getPatientDocumentAsync = createAsyncThunk(
  'patients/document',
  (documentID: string) => fetchPatientDocument(documentID),
);

export const createDocumentAsync = createAsyncThunk(
  'documents/createCurrent',
  ({
    patientID,
    document,
    type,
  }: {
    patientID: string;
    document: RcFile;
    type: DocumentType;
  }) => createDocument(patientID, document, type),
);

export const generateCerfaAsync = createAsyncThunk(
  'patients/currentGenerateCerfa',
  ({
    patient,
    user,
    doctor,
    insured,
    supported,
    treatment,
    prescription,
  }: {
    patient: Patient;
    insured: Insured;
    supported: Supported;
    treatment: Treatment;
    user: User;
    doctor: Doctor;
    prescription: Prescription;
  }) =>
    generateCerfa(
      patient,
      insured,
      supported,
      treatment,
      user,
      doctor,
      prescription,
    ),
);

export interface IGetPatientListAsync {
  name?: string;
  status?: PatientStatus | null;
  endDate?: Dayjs | null;
  page?: number;
  startDate?: Dayjs | null;
  doctorID?: string;
  pageSize?: number;
  sortOrder?: 'ascend' | null;
}

let getPatientListAsyncController: AbortController;

export const getPatientListAsync = createAsyncThunk(
  'patients/list',
  (params: IGetPatientListAsync) => {
    const {
      name,
      status = null,
      endDate,
      page = 1,
      startDate,
      doctorID = undefined,
      pageSize = 10,
      sortOrder = null,
    } = params;
    const searchParams = new URLSearchParams({
      page_size: pageSize.toString(),
      page: page.toString(),
    });

    if (name) {
      searchParams.append('name', name);
    }
    if (startDate) {
      searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
    }
    if (endDate) {
      searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
    }
    if (status) {
      searchParams.append('status', status);
    }
    if (doctorID) {
      searchParams.append('doctor_id', doctorID);
    }
    if (sortOrder === 'ascend') {
      searchParams.append('sort_order', 'ascending');
    }

    if (getPatientListAsyncController?.abort) {
      getPatientListAsyncController.abort();
    }

    getPatientListAsyncController = new AbortController();

    return getPatients(searchParams, getPatientListAsyncController.signal);
  },
);

export interface IUpdatePatientAsync {
  patientID: string;
  observations?: string;
  status?: PatientStatus;
  nonVenu?: PatientNonVenuOption;
}
export const updatePatientAsync = createAsyncThunk(
  'patients/update',
  ({ patientID, observations, status, nonVenu }: IUpdatePatientAsync) =>
    patchPatient(patientID, {
      observations,
      status,
      nonVenu,
    }),
);

export const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    resetCurrentPatient: (state) => {
      delete state.current;
      delete state.currentRequestStatus;
      delete state.updateCurrentRequestStatus;
      delete state.generatedCERFAContent;
    },
    resetPatientLists: (state) => {
      state.list = [];
      delete state.listRequestStatus;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientAsync.pending, (state) => {
        state.currentRequestStatus = REQUEST_STATUSES.PENDING;
      })
      .addCase(getPatientAsync.fulfilled, (state, { payload }) => {
        state.currentRequestStatus = REQUEST_STATUSES.SUCCESS;
        state.current = payload;
      })
      .addCase(getPatientAsync.rejected, (state) => {
        state.currentRequestStatus = REQUEST_STATUSES.ERROR;
      })
      .addCase(getPatientDocumentsAsync.pending, (state) => {
        state.documentsRequestStatus = REQUEST_STATUSES.PENDING;
      })
      .addCase(getPatientDocumentsAsync.fulfilled, (state, { payload }) => {
        state.documentsRequestStatus = REQUEST_STATUSES.SUCCESS;
        state.documents = payload;
      })
      .addCase(getPatientDocumentsAsync.rejected, (state) => {
        state.documentsRequestStatus = REQUEST_STATUSES.ERROR;
      })
      .addCase(createDocumentAsync.pending, (state) => {
        state.createDocumentRequestStatus = REQUEST_STATUSES.PENDING;
      })
      .addCase(createDocumentAsync.fulfilled, (state, { payload }) => {
        state.createDocumentRequestStatus = REQUEST_STATUSES.SUCCESS;
        state.documents?.push(payload);
      })
      .addCase(createDocumentAsync.rejected, (state) => {
        state.createDocumentRequestStatus = REQUEST_STATUSES.ERROR;
      })
      .addCase(getPatientDocumentAsync.pending, (state) => {
        state.fetchDocumentRequestStatus = REQUEST_STATUSES.PENDING;
      })
      .addCase(getPatientDocumentAsync.fulfilled, (state, { payload }) => {
        state.fetchDocumentRequestStatus = REQUEST_STATUSES.SUCCESS;
        window.open(payload?.uri, '_blank');
      })
      .addCase(getPatientDocumentAsync.rejected, (state) => {
        state.fetchDocumentRequestStatus = REQUEST_STATUSES.ERROR;
      })
      .addCase(getPatientListAsync.pending, (state) => {
        state.listRequestStatus = REQUEST_STATUSES.PENDING;
      })
      .addCase(getPatientListAsync.fulfilled, (state, { payload }) => {
        state.listRequestStatus = REQUEST_STATUSES.SUCCESS;
        state.list = payload;
      })
      .addCase(getPatientListAsync.rejected, (state) => {
        state.listRequestStatus = REQUEST_STATUSES.ERROR;
      })
      .addCase(generateCerfaAsync.pending, (state) => {
        state.generatedCERFAContent = '';
        state.generatedCERFARequestStatus = REQUEST_STATUSES.PENDING;
      })
      .addCase(generateCerfaAsync.fulfilled, (state, { payload }) => {
        state.generatedCERFAContent = payload;
        state.generatedCERFARequestStatus = REQUEST_STATUSES.SUCCESS;
      })
      .addCase(generateCerfaAsync.rejected, (state) => {
        state.generatedCERFARequestStatus = REQUEST_STATUSES.ERROR;
      })
      .addCase(updatePatientAsync.pending, (state) => {
        state.updateCurrentRequestStatus = REQUEST_STATUSES.PENDING;
      })
      .addCase(updatePatientAsync.fulfilled, (state, { meta }) => {
        state.updateCurrentRequestStatus = REQUEST_STATUSES.SUCCESS;
        if (state.current && meta.arg.observations) {
          state.current.rdv_3___observations_medecin = meta.arg.observations;
        }
        if (state.current && meta.arg.status) {
          state.current.rdv_3___statut2 = meta.arg.status;
        }
        if (state.current && meta.arg.nonVenu) {
          state.current.vu_en_tele_consultation = meta.arg.nonVenu;
        }
      })
      .addCase(updatePatientAsync.rejected, (state) => {
        state.updateCurrentRequestStatus = REQUEST_STATUSES.ERROR;
      });
  },
});

export const { resetCurrentPatient, resetPatientLists } = patientsSlice.actions;
export const getGeneratedCerfa = (state: RootState) =>
  state.patients.generatedCERFAContent;
export const getGeneratedPDFRequestStatus = (state: RootState) =>
  state.patients.generatedCERFARequestStatus;
export const getCurrentPatient = (state: RootState) => state.patients.current;
export const getPatientDocuments = (state: RootState) =>
  state.patients.documents;
export const getCurrentPatientRequestStatus = (state: RootState) =>
  state.patients.currentRequestStatus;
export const getDocumentsRequestStatus = (state: RootState) =>
  state.patients.documentsRequestStatus;
export const getCreateDocumentRequestStatus = (state: RootState) =>
  state.patients.createDocumentRequestStatus;
export const getUpdatePatientRequestStatus = (state: RootState) =>
  state.patients.updateCurrentRequestStatus;
export const getPatientList = (state: RootState) => state.patients.list;
export const getPatientListRequestStatus = (state: RootState) =>
  state.patients.listRequestStatus;

export default patientsSlice.reducer;
