import {
  Action,
  ThunkAction,
  configureStore,
  PreloadedState,
  combineReducers,
} from '@reduxjs/toolkit';
import timeslotsReducer from 'stores/agenda/timeslotsSlice';
import authReducer from 'stores/auth/authSlice';
import doctorsReducer from 'stores/doctors/doctorsSlice';
import meetingsReducer from 'stores/meetings/meetingsSlice';
import patientsReducer from 'stores/patients/patientsSlice';
import userReducer from 'stores/user/userSlice';
import authMiddleware from './authMiddleware';

export const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  doctors: doctorsReducer,
  patients: patientsReducer,
  timeslots: timeslotsReducer,
  meetings: meetingsReducer,
});
export type RootState = ReturnType<typeof rootReducer>;

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(authMiddleware),
    reducer: rootReducer,
    preloadedState,
  });

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
