import { Dayjs } from 'dayjs';
import type { Doctor } from './doctorsTypes';
import type { Patient } from './patientsTypes';

export const MEETING_TYPES = {
  RDV_1: 'RDV_1',
  RDV_2: 'RDV_2',
  RDV_3: 'RDV_3',
  RDV_PPC: 'RDV_PPC',
  RDV_SWITCH: 'RDV_SWITCH',
  None: 'None',
} as const;

type MeetingTypeKeys = keyof typeof MEETING_TYPES;
export type MeetingType = (typeof MEETING_TYPES)[MeetingTypeKeys];

export const MEETING_STATUSES = {
  CANCELED: 'CANCELED',
  REFUNDED: 'REFUNDED',
  ACCEPTED: 'ACCEPTED',
} as const;

type MeetingStatusKeys = keyof typeof MEETING_STATUSES;
export type MeetingStatus = (typeof MEETING_STATUSES)[MeetingStatusKeys];
export const MEETING_DURATION = {
  SHORT: 'short',
  LONG: 'long',
} as const;
export const MEETING_DURATION_TIME = {
  SHORT: '15 minutes',
  LONG: '30 minutes',
} as const;

type MeetingDurationKeys = keyof typeof MEETING_DURATION;
export type MeetingDuration = (typeof MEETING_DURATION)[MeetingDurationKeys];

export interface Meeting {
  id: string;
  wp_id: string;
  start_date: Dayjs;
  end_date: Dayjs;
  patient: Patient;
  doctor: Doctor;
  type: MeetingType;
  status: MeetingStatus;
  metadata: {
    hasCMU: boolean;
    employee: string;
    employee_id: string;
    address: string;
    isMigrate?: boolean; // use for meeting add from migration
  };
  locks: {
    rdv_1?: boolean;
    rdv_2?: boolean;
    reschedule_rdv_2?: boolean;
    rdv_3_ok_lancement?: boolean;
    rdv_3?: boolean;
    transmission_psad_ok?: boolean;
    rdv_4?: boolean;
    rdv_switch?: boolean;
  };
  uri: string;
}

export const SOON_DIFF = 15;

export const MEETING_CARD_STATUSES = {
  OK: 'OK', // Meeting is Schedule, everything is good. Go check nextRDVField to know the next RDV
  TO_JUSTIFY_CMU: 'TO_JUSTIFY_CMU',
  TO_JUSTIFY_PRESCRIPTION: 'TO_JUSTIFY_PRESCRIPTION',
  TO_SCHEDULE_1: 'TO_SCHEDULE_1',
  TO_SCHEDULE_2: 'TO_SCHEDULE_2',
  TO_RESCHEDULE_2: 'TO_RESCHEDULE_2',
  TO_SCHEDULE_3: 'TO_SCHEDULE_3',
  WAITING_FOR_VALIDATION_RDV_3: 'WAITING_FOR_VALIDATION_RDV_3',
  TO_SEND_PSAD: 'TO_SEND_PSAD',
  TO_SCHEDULE_PPC: 'TO_SCHEDULE_PPC',
  TO_SCHEDULE_PSG: 'TO_SCHEDULE_PSG',
  TO_SCHEDULE_SWITCH: 'TO_SCHEDULE_SWITCH',
  CANCELED_TO_SCHEDULE_1: 'CANCELED_TO_SCHEDULE_1',
  CANCELED_TO_SCHEDULE_2: 'CANCELED_TO_SCHEDULE_2',
  CANCELED_TO_SCHEDULE_3: 'CANCELED_TO_SCHEDULE_3',
  CANCELED_TO_SCHEDULE_PPC: 'CANCELED_TO_SCHEDULE_PPC',
  CANCELED_TO_SCHEDULE_SWITCH: 'CANCELED_TO_SCHEDULE_SWITCH',
  FINISH: 'FINISH',
} as const;

type MeetingCardStatusKeys = keyof typeof MEETING_CARD_STATUSES;
export type MeetingCardStatus =
  (typeof MEETING_CARD_STATUSES)[MeetingCardStatusKeys];
