import cx from 'classnames';
import type { ComponentProps, PropsWithChildren } from 'react';

export default function Card({
  children = null,
  className = '',
}: PropsWithChildren<ComponentProps<'div'>>) {
  return (
    <div className={cx(className, 'p-6 bg-background-z-10 rounded-2xl')}>
      {children}
    </div>
  );
}
