import { CalendarOutlined } from '@ant-design/icons';
import { Button, Modal, Spin } from 'antd';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import Title from 'commons/components/layout/Title';
import { REQUEST_STATUSES } from 'commons/types/common';
import {
  MEETING_DURATION,
  MEETING_DURATION_TIME,
} from 'commons/types/meetingsTypes';
import InformationItem from 'components/informations/InformationItem';
import Card from 'components/layout/Card';
import UserForm from 'components/user/UserForm';
import { signoutAsync } from 'stores/auth/authSlice';
import { useCurrentDoctor } from 'stores/doctors/doctorsHooks';
import { updateDoctorAsync } from 'stores/doctors/doctorsSlice';
import { useAppDispatch } from 'stores/hooks';
import { useUser } from 'stores/user/userHooks';
import { resetUser } from 'stores/user/userSlice';

export default function Account() {
  const dispatch = useAppDispatch();
  const [user, userRequestStatus] = useUser();
  const [doctor, doctorRequestStatus] = useCurrentDoctor();
  const [editionMode, setEditionMode] = useState(false);
  const onClickSignOut = useCallback(
    () => dispatch(signoutAsync()),
    [dispatch],
  );
  const onClickRetry = useCallback(() => dispatch(resetUser()), [dispatch]);
  const onClickEdit = useCallback(() => setEditionMode(true), [setEditionMode]);
  const onCloseEdit = useCallback(
    () => setEditionMode(false),
    [setEditionMode],
  );
  const onSaveUser = useCallback(
    (newUser: any) => {
      dispatch(
        updateDoctorAsync({
          ...newUser,
          id: doctor?.id,
        }),
      );
      setEditionMode(false);
    },
    [doctor, setEditionMode, dispatch],
  );

  const informations: { name: ReactNode; value: ReactNode }[] = useMemo(() => {
    if (!user) {
      return [];
    }
    const cityAddress = [doctor?.zip, doctor?.city]
      .filter((info) => Boolean(info))
      .join(' ');
    const designation = [doctor?.r_social, doctor?.n_struct]
      .filter((info) => Boolean(info))
      .join(' - ');

    return [
      {
        name: 'Nom',
        value: [user.firstName, user.lastName]
          .filter((name) => Boolean(name))
          .join(' '),
      },
      {
        name: 'Spécialité',
        value: doctor?.speciality,
      },
      {
        name: 'Adresse email',
        value: user.email,
      },
      {
        name: 'Nº de téléphone',
        value: user.phone,
      },
      {
        name: 'Raison sociale - Nº de structure',
        value: designation,
      },
      {
        name: 'Nº RPPS',
        value: doctor?.rpps,
      },
      {
        name: 'Adresse de facturation',
        value: (
          <span>
            {doctor?.address}
            <br />
            {cityAddress}
          </span>
        ),
      },
      {
        name: 'Durée des RDVs',
        value: (
          <span>
            {doctor?.meeting_duration === MEETING_DURATION.SHORT
              ? MEETING_DURATION_TIME.SHORT
              : MEETING_DURATION_TIME.LONG}
          </span>
        ),
      },
    ];
  }, [user, doctor]);

  const handleGoogleCalendarConnect = useCallback(() => {
    const backendAuthUrl = `${process.env.REACT_APP_ROOT_API_URL}/api/doctors/google/auth`;
    window.location.href = backendAuthUrl;
  }, []);

  const handleGoogleCalendarDisconnect = useCallback(() => {
    dispatch(
      updateDoctorAsync({
        id: doctor?.id || '',
        user: { ...user },
        doctor: {
          ...doctor,
          google_calendar_connected: false,
        },
      }),
    );
  }, [user, doctor, dispatch]);

  if (userRequestStatus === REQUEST_STATUSES.PENDING) {
    return (
      <div className='flex flex-col items-center justify-center grow'>
        <Spin size='large' />
      </div>
    );
  }

  if (!user || !doctor) {
    return (
      <div className='flex flex-col items-center justify-center grow'>
        <Title>Impossible de retrouver vos informations personnnelles…</Title>
        <Button onClick={onClickRetry}>Réessayer</Button>
      </div>
    );
  }

  return (
    <div>
      <div className='flex flex-wrap items-center space-x-4'>
        <Title main>Mes informations</Title>
        <Button
          onClick={onClickSignOut}
          className='mb-4'
          type='link'
          danger
        >
          Se déconnecter
        </Button>
      </div>
      <Card className='relative grid grid-cols-1 gap-4 mb-6 sm:grid-cols-2'>
        <div className='absolute right-4 top-4'>
          <Button
            loading={doctorRequestStatus === REQUEST_STATUSES.PENDING}
            type='primary'
            onClick={onClickEdit}
          >
            Mettre à jour
          </Button>
        </div>
        {informations.map(({ name, value }) => (
          <InformationItem
            name={name}
            value={value}
            key={name?.toString()}
          />
        ))}
      </Card>
      <div className='flex flex-col items-start'>
        {doctor?.google_calendar_connected ? (
          <Button
            loading={doctorRequestStatus === REQUEST_STATUSES.PENDING}
            onClick={handleGoogleCalendarDisconnect}
            className='google-calendar-btn google-calendar-btn-connected'
            icon={<CalendarOutlined className='text-lg mr-2' />}
          >
            Déconnecter de Google Calendar
          </Button>
        ) : (
          <Button
            onClick={handleGoogleCalendarConnect}
            className='google-calendar-btn'
            icon={<CalendarOutlined className='text-lg mr-2' />}
          >
            Connecter à Google Calendar
          </Button>
        )}
      </div>
      <Modal
        open={editionMode}
        onCancel={onCloseEdit}
        footer={null}
        width='768px'
        title='Mise à jour de mes informations'
      >
        {editionMode && (
          <UserForm
            onSave={onSaveUser}
            user={user}
            doctor={doctor}
          />
        )}
      </Modal>
    </div>
  );
}
