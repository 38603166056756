import { ConfigProvider } from 'antd';
import frFR from 'antd/locale/fr_FR';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/fr';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Router from 'routes/Router';
import { setupStore } from 'stores/store';
import reportWebVitals from './reportWebVitals';
import './index.css';

dayjs.locale('fr');
dayjs.extend(minMax);
dayjs.extend(LocalizedFormat);

const container = document.getElementById('root')!;
const root = createRoot(container);
const theme = {
  token: {
    fontFamily: 'Montserrat',
    colorPrimary: '#fc6a38',
    colorTextBase: '#1E2248',
  },
  components: {
    Button: {
      borderRadius: 20,
      borderRadiusLG: 20,
      borderRadiusSM: 20,
      paddingContentHorizontal: 32,
    },
    Select: {
      borderRadius: 24,
    },
  },
};

root.render(
  <Provider store={setupStore()}>
    <ConfigProvider
      theme={theme}
      locale={frFR}
    >
      <Router />
    </ConfigProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
