import type { Rule } from 'antd/es/form';

const REQUIRED_FIELD_RULES = [
  {
    required: true,
    message: 'Ce champ est obligatoire',
  },
];
const REQUIRED_PHONE_NUMBER_RULES = [
  {
    required: true,
    validator: (_: Rule, value: string) => {
      const cleanedValue = value.split(' ').join('');
      const regex = /^0[0-9]{9}$|^\+33[0-9]{9}$/is;

      if (cleanedValue.length === 0) {
        return Promise.reject(new Error('Ce champ est obligatoire'));
      }
      if (regex.test(cleanedValue)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Numéro de téléphone invalide'));
    },
  },
];
const REQUIRED_RPPS_NUMBER_RULES = [
  {
    required: true,
    validator: (_: Rule, value: string) => {
      const cleanedValue = value.split(' ').join('');
      const regex = /^[0-9]{11}$/is;

      if (cleanedValue.length === 0) {
        return Promise.reject(new Error('Ce champ est obligatoire'));
      }
      if (regex.test(cleanedValue)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Numéro RPPS invalide'));
    },
  },
];
const REQUIRED_STRUCT_NUMBER_RULES = [
  {
    required: true,
    validator: (_: Rule, value: string) => {
      const cleanedValue = value.split(' ').join('');
      const regex = /^[0-9A-Z]{9}$|^[0-9]{14}$/is;

      if (cleanedValue.length === 0) {
        return Promise.reject(new Error('Ce champ est obligatoire'));
      }
      if (regex.test(cleanedValue)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Numéro de structure invalide'));
    },
  },
];

export {
  REQUIRED_FIELD_RULES,
  REQUIRED_PHONE_NUMBER_RULES,
  REQUIRED_RPPS_NUMBER_RULES,
  REQUIRED_STRUCT_NUMBER_RULES,
};
