import { Modal, Form, Divider } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { REQUEST_STATUSES } from 'commons/types/common';
import { Doctor } from 'commons/types/doctorsTypes';
import { Patient } from 'commons/types/patientsTypes';
import { useAppDispatch, useAppSelector } from 'stores/hooks';
import {
  generateCerfaAsync,
  getGeneratedCerfa,
  getGeneratedPDFRequestStatus,
} from 'stores/patients/patientsSlice';
import {
  initInsured,
  initPrescription,
  initSupported,
  initTreatment,
} from 'stores/patients/patientsTypes';
import { User } from 'stores/user/userTypes';
import CERFAPatient from './CERFAPatient';
import CERFAPrescription from './CERFAPrescription';
import CERFASupported from './CERFASupported';
import CERFATreatment from './CERFATreatment';

interface CERFAModalProps {
  patient: Patient;
  doctor: Doctor;
  user: User;
  open: boolean;
  onCancel: () => void;
  setOpenModal: (isOpen: boolean) => void;
}

export default function CERFAModal({
  patient,
  doctor,
  user,
  open,
  onCancel,
  setOpenModal,
}: CERFAModalProps) {
  const dispatch = useAppDispatch();
  const cerfaContent = useAppSelector(getGeneratedCerfa);
  const cerfaGeneratedRequestStatus = useAppSelector(
    getGeneratedPDFRequestStatus,
  );
  const [form] = Form.useForm();

  const [insured] = useState(initInsured(patient));
  const [supported] = useState(initSupported());
  const [treatment] = useState(initTreatment());
  const [prescription] = useState(initPrescription());

  const generateAndDownload = useCallback(
    (values: any) => {
      dispatch(
        generateCerfaAsync({
          patient,
          ...values,
          user,
          doctor,
        }),
      );
    },
    [dispatch, patient, user, doctor],
  );
  const onSubmit = useCallback(() => {
    form.submit();
  }, [form]);
  const downloadCERFA = useCallback(
    (values: any) => {
      generateAndDownload(values);
      form.resetFields();
      setOpenModal(false);
    },
    [form, generateAndDownload, setOpenModal],
  );

  useEffect(() => {
    if (cerfaContent) {
      const downloadLink = document.createElement('a');
      const fileName = `${patient.member.lastName}_${patient.member.firstName}_cerfa.pdf`;
      downloadLink.href = cerfaContent || '';
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }, [cerfaContent, patient.member.firstName, patient.member.lastName]);

  return (
    <Modal
      open={open}
      title='Générer le CERFA'
      okText='Générer'
      cancelText='Annuler'
      onCancel={onCancel}
      width='768px'
      confirmLoading={cerfaGeneratedRequestStatus === REQUEST_STATUSES.PENDING}
      onOk={onSubmit}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={downloadCERFA}
        initialValues={{
          insured,
          supported,
          treatment,
          prescription,
        }}
        scrollToFirstError
      >
        <CERFAPatient form={form} />
        <Divider />
        <CERFASupported />
        <Divider />
        <CERFATreatment form={form} />
        <Divider />
        <CERFAPrescription form={form} />
      </Form>
    </Modal>
  );
}
